import React, { ReactNode, useEffect, useState } from 'react';
import ActionButton from 'view/components/buttons/ActionButton';
import { useFormValidation } from 'contexts/FormValidationContext';
import { useFormContext } from 'contexts/FormContext';

interface MultiPageFormProps {
  pages: ReactNode[];
  pageSubmitCallbacks?: (((formData: any) => Promise<any>) | undefined)[];
  currentPage: number;
  setCurrentPage: (page: number) => void;
  onSubmit: (formData: any) => void;
  onExit: () => void;
}

const MultiPageForm: React.FC<MultiPageFormProps> = ({
  pages,
  pageSubmitCallbacks,
  currentPage,
  setCurrentPage,
  onSubmit,
  onExit,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const { isPageValid, setPageValidity } = useFormValidation();
  const { formData } = useFormContext();

  const nextPage = () => {
    const callback = pageSubmitCallbacks?.[currentPage];

    if (callback) {
      callback(formData)
        .then(() => {
          // Only move to the next page if the callback is successful
          if (currentPage < pages.length - 1) {
            setCurrentPage(currentPage + 1);
          } else {
            onSubmit(formData);
          }
          setErrorMessage(undefined);
        })
        .catch((error) => {
          console.error('Error in page callback:', error);
          setErrorMessage(error.message);
          // Do not change the page
        });
    } else {
      // If no callback, just move to the next page
      if (currentPage < pages.length - 1) {
        setCurrentPage(currentPage + 1);
      } else {
        onSubmit(formData);
      }
      setErrorMessage(undefined);
    }
  };
  const prevPage = () => setCurrentPage(currentPage - 1);

  useEffect(() => {
    return () => {
      setPageValidity(false);
    };
  }, [setPageValidity]);

  return (
    <>
      {pages.map((page, idx) => currentPage === idx && page)}

      {errorMessage && (
        <span className="label-normal danger">{errorMessage}</span>
      )}

      <div className="row">
        {currentPage > 0 ? (
          <ActionButton type="delete" onClick={prevPage} label="Back" />
        ) : (
          <ActionButton type="delete" onClick={onExit} label="Cancel" />
        )}
        {currentPage < pages.length - 1 ? (
          <ActionButton
            type="go"
            onClick={nextPage}
            disabled={!isPageValid}
            label="Next"
          />
        ) : (
          <ActionButton
            type="go"
            onClick={nextPage}
            disabled={!isPageValid}
            label="Submit"
          />
        )}
      </div>
    </>
  );
};

export default MultiPageForm;
