import React, { ReactNode, useState } from 'react';
import { AppPage } from 'view/components/common/AppPage';
import SidebarButton from 'view/components/buttons/SidebarButton';
import { ProfileForm } from 'view/components/profile/ProfileForm';
import { SubscriptionForm } from 'view/components/profile/SubscriptionForm';
import { PrivacyPolicy } from 'view/components/common/PrivacyPolicy';
import { TermsOfService } from 'view/components/common/TermsOfService';
import AppSidebar from 'view/components/common/Sidebar';
import contentService from 'services/contentService';

const content: Record<string, ReactNode> = {
  profile: <ProfileForm />,
  subscription: <SubscriptionForm />,
  privacy: <PrivacyPolicy />,
  terms: <TermsOfService />,
};

export const TeacherProfilePage: React.FC = () => {
  const [activeContent, setActiveContent] = useState<ReactNode>(
    <ProfileForm />,
  );
  const pageContent = contentService.getTeacherProfilePage();

  const handleToggleContent = (event: React.MouseEvent<HTMLButtonElement>) => {
    const button = event.currentTarget;
    setActiveContent(content[button.id]);
  };

  const handleExit = () => {
    window.history.back();
  };

  return (
    <AppPage>
      <div className="app-page-content">
        <AppSidebar onExit={handleExit}>
          <>
            {pageContent.sidebar.nav.map((navItem: Record<string, any>) => (
              <SidebarButton
                key={navItem.id}
                id={navItem.id}
                type="default"
                onClick={handleToggleContent}
                label={navItem.label}
              />
            ))}
          </>
        </AppSidebar>
        {activeContent}
      </div>
    </AppPage>
  );
};
