import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from 'contexts/UserContext';
import { ClassroomProvider } from 'contexts/ClassroomContext';
import { AssignmentProvider } from 'contexts/AssignmentContext';
import { LoadingProvider } from 'contexts/LoadingContext';
import { SidebarStateProvider } from 'contexts/SidebarStateContext';
import { UserPreferencesProvider } from 'contexts/UserPreferencesContext';
import { AlertProvider } from 'contexts/AlertContext';
import { GradebookProvider } from 'contexts/TeacherGradebookContext';

import './index.css';
import './App.css';
import './view/style/common/inputs.css';
import './view/style/common/text.css';
import './view/style/common/layout.css';
import './view/style/common/components.css';
import './view/style/common/components/form.css';
import './view/style/common/animations.css';
import { ConfirmationProvider } from 'contexts/ConfirmationContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <UserProvider>
      <ClassroomProvider>
        <AssignmentProvider>
          <LoadingProvider>
            <SidebarStateProvider>
              <UserPreferencesProvider>
                <AlertProvider>
                  <GradebookProvider>
                    <ConfirmationProvider>
                      <App />
                    </ConfirmationProvider>
                  </GradebookProvider>
                </AlertProvider>
              </UserPreferencesProvider>
            </SidebarStateProvider>
          </LoadingProvider>
        </AssignmentProvider>
      </ClassroomProvider>
    </UserProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
