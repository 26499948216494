import React, { useEffect, useState } from 'react';
import { UserInput } from 'view/components/common/UserInput';
import Classroom from 'models/Classroom';
import ActionButton from 'view/components/buttons/ActionButton';
import { useClassroomContext } from 'contexts/ClassroomContext';
import teacherService from 'services/teacherService';
import { AppPage } from 'view/components/common/AppPage';
import useApiCall from 'contexts/ApiCall';
import contentService from 'services/contentService';
import Form from 'view/components/common/Form';
import { useAlert } from 'contexts/AlertContext';
import cleverService from 'services/cleverService';
import { useUserContext } from 'contexts/UserContext';

const delete_forever: string =
  require('assets/icons/delete_forever.svg').default;
const save: string = require('assets/icons/save.svg').default;
const lock: string = require('assets/icons/lock.svg').default;

interface ClassroomSettingsProps {
  classroom: Classroom;
  onExit: (classroom: Classroom) => void;
}

export const ClassroomSettings: React.FC<ClassroomSettingsProps> = ({
  classroom,
  onExit,
}) => {
  const { user } = useUserContext();
  const [editClassroom, setEditClassroom] = useState<Classroom>();
  const [showDeletePrompt, setShowDeletePrompt] = useState<boolean>(false);
  const [cleverClassrooms, setCleverClassrooms] = useState<any[]>();
  const { setAlert } = useAlert();
  const { setClassroom } = useClassroomContext();
  const constants = contentService.getTeacherConstants();
  const makeApiCall = useApiCall();

  useEffect(() => {
    setEditClassroom(Classroom.fromClassroom(classroom));

    if (cleverService.isAuthenticated() && user?.clever_id) {
      makeApiCall(cleverService.getClassrooms, user.clever_id)
        .then((respClassrooms) => setCleverClassrooms(respClassrooms))
        .catch((error) =>
          setAlert({ silent: true, type: 'danger', message: error.message }),
        );
    } else if (classroom.clever_id) {
      setCleverClassrooms([classroom.clever_id]);
    }

    return () => {
      setAlert(undefined);
    };
    // eslint-disable-next-line
  }, [user?.clever_id, classroom, setAlert, makeApiCall]);

  const updateClassroom = (event: any) => {
    if (!classroom) return;
    var value = event.target.value;
    if (event.target.id === 'language') {
      const langCode = constants.languages.find(
        (l: any) => l.name === value,
      )?.code;
      if (langCode) {
        value = langCode;
      }
    }
    setEditClassroom((prevClassroom: any) => {
      return Classroom.fromServerClassroom({
        ...prevClassroom,
        [event.target.id]: value,
      });
    });
  };

  const handleSaveChanges = (event: any) => {
    event.preventDefault();
    setAlert(undefined);
    makeApiCall(teacherService.updateClassroom, editClassroom)
      .then((respClassroom) => {
        setClassroom(Classroom.fromServerClassroom(respClassroom));
        onExit(respClassroom);
      })
      .catch((error) =>
        setAlert({ silent: true, type: 'danger', message: error.message }),
      );
  };

  const handleDeleteClassroom = (event: any) => {
    event.preventDefault();
    setAlert(undefined);
    makeApiCall(teacherService.deleteClassroom, classroom)
      .then((resp) => {
        setClassroom(null);
        setEditClassroom(undefined);
      })
      .catch((error) =>
        setAlert({ silent: true, type: 'danger', message: error.message }),
      );
  };

  const handleToggleDeletePrompt = (event: any) => {
    event.preventDefault();
    setShowDeletePrompt((prev) => !prev);
  };

  const handleCleverClassroomImport = (event: any) => {
    const cleverClassroom = cleverClassrooms?.find(
      (cc) => cc.name === event.target.value,
    );
    if (!cleverClassroom) return;

    setEditClassroom((prevClassroom) => {
      return Classroom.fromServerClassroom({
        ...prevClassroom,
        name: cleverClassroom.name,
        clever_id: cleverClassroom.id,
      });
    });
  };

  if (!editClassroom) return <AppPage />;

  return (
    <Form label="Class Settings">
      <UserInput
        id="name"
        label="Classroom Name"
        type="text"
        value={editClassroom?.name}
        onChange={updateClassroom}
      />
      <UserInput
        id="reading_level"
        label="Reading Groups"
        hint="Positive integer"
        type="from-to"
        rangeValue={{
          min: editClassroom?.min_reading_level,
          max: editClassroom?.max_reading_level,
        }}
        onChange={updateClassroom}
      />
      <UserInput
        id="classroom_id"
        label="Classroom ID"
        hint="Cannot be changed"
        type="text"
        readonly={true}
        value={editClassroom?.getId()}
        inlineLabel={<img src={lock} alt="" />}
        onChange={() => {}}
      />
      <UserInput
        id="access_code"
        label="Access Code"
        hint="Maximum of 8 characters "
        type="text"
        value={editClassroom?.access_code}
        onChange={updateClassroom}
      />
      <UserInput
        id="language"
        label="English Dialect"
        // hint='Positive integer'
        type="select"
        options={constants.languages}
        optionsField="name"
        value={
          constants.languages.find(
            (l: any) => l.code === editClassroom?.language,
          )?.name
        }
        onChange={updateClassroom}
      />
      <div className="row">
        <ActionButton
          type="delete"
          onClick={handleToggleDeletePrompt}
          label="Delete class"
          icon={delete_forever}
        />
        <ActionButton
          type="go"
          onClick={handleSaveChanges}
          label="Save changes"
          icon={save}
        />
      </div>
      {cleverClassrooms && (
        <UserInput
          id="clever-classroom"
          label="Import from Clever"
          type="select"
          options={cleverClassrooms}
          optionsField="name"
          defaultOption="None"
          onChange={handleCleverClassroomImport}
        />
      )}
      {showDeletePrompt && (
        <>
          <span className="label-medium" style={{ color: '#B1243F' }}>
            WARNING: Your entire class will be irreversibly deleted. Are you
            sure?
          </span>
          <div className="row">
            <ActionButton
              type="delete"
              onClick={handleDeleteClassroom}
              label="Yes, delete"
            />
            <ActionButton
              type="go"
              onClick={handleToggleDeletePrompt}
              label="Nevermind"
            />
          </div>
        </>
      )}
    </Form>
  );
};
