import React, { useState, useEffect, useCallback, useRef } from 'react';
import speechRecognitionService from 'services/speechRecognitionService';
import parse from 'html-react-parser';
import Button from 'view/components/buttons/Button';
import Book from 'models/Book';
import Timer from 'utils/Timer';
import MissedWord from 'models/MissedWord';
import ReadingUtils from 'utils/ReadingUtils';
import MissedWordDisplay from '../student/MissedWordDisplay';
// import transcriptionService from 'services/transcriptionService';
import { SpeechMode } from 'utils/types';
import ReadingFrame from './ReadingFrame';

import 'view/style/student/assignment.css';

const play: string = require('assets/icons/play_circle_filled.svg').default;
const pause: string = require('assets/icons/pause_circle.svg').default;
const rotate: string = require('assets/icons/rotate.svg').default;
const wormy_a: string = require('assets/images/logos/wormy-1.png');
const wormy_b: string = require('assets/images/logos/wormy-book-2.png');

interface BookReaderProps {
  book: Book;
  startIndex: number;
  missedWords?: MissedWord[];
  isReading: boolean;
  showMissedWordPopup?: boolean;
  missedWordInterval?: number;
  customEndMessage?: string;
  dyslexicMode?: boolean;
  speechMode?: SpeechMode;
  hideQuestions?: boolean;
  setIsReading: (value: boolean) => void;
  onCompletion?: () => void;
  onStopReading?: (index: number) => void;
  onMissedWord: (word: string, index: number, known: boolean) => void;
  onViewQuestions?: () => void;
}

export interface HtmlContent {
  content: string;
  index: number;
}

enum ReadingState {
  Paused = 0,
  Reading = 1,
  Completed = 2,
  MissedWord = 3,
}

export const BookReader: React.FC<BookReaderProps> = ({
  book,
  startIndex,
  missedWords,
  isReading,
  showMissedWordPopup = true,
  missedWordInterval = 3,
  customEndMessage,
  dyslexicMode = false,
  speechMode = SpeechMode.Close,
  hideQuestions = true,
  setIsReading,
  onCompletion,
  onStopReading,
  onMissedWord,
  onViewQuestions,
}) => {
  const [wordIndex, setWordIndex] = useState(0);
  const [htmlContent, setHtmlContent] = useState<string>('');
  const [words, setWords] = useState<string[]>();
  const [timer] = useState<Timer>(new Timer());
  const [readingState, setReadingState] = useState<ReadingState>(
    ReadingState.Paused,
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const missedWordTimerKey = 'missedWord';
  const autosaveTimerKey = 'autosave';

  useEffect(() => {
    const targetElement = document.getElementById((wordIndex - 1).toString());
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [htmlContent, wordIndex, readingState, isReading]);

  useEffect(() => {
    if (
      words &&
      words.length > 0 &&
      wordIndex >= words.length &&
      readingState !== ReadingState.MissedWord
    ) {
      setReadingState(ReadingState.Completed);
      setIsReading(false);
      if (onCompletion) onCompletion();
    }
    if (
      words &&
      words.length > 0 &&
      wordIndex > 0 &&
      wordIndex < words.length - 1 &&
      !timer.timerExists(autosaveTimerKey) &&
      readingState !== ReadingState.Paused
    ) {
      timer.start(autosaveTimerKey, () => autosave(wordIndex), 15000);
    }
    // eslint-disable-next-line
  }, [wordIndex, words, readingState]);

  useEffect(() => {
    return () => {
      timer.clear(autosaveTimerKey);
      speechRecognitionService.stopSpeechRecognition();
      // transcriptionService.stopTranscription();
      timer.clear(missedWordTimerKey);
    };
  }, [timer]);

  useEffect(() => {
    if (!book || !book.html_content || words) return;
    const bookWords = book.words ?? book.getWords();
    setHtmlContent(ReadingUtils.createHtmlString(bookWords, book.html_content));
    setWords(bookWords);
    if (startIndex > 0 && missedWords) {
      // mark the reading up until the current word
      const missedWordIdxs: number[] = missedWords.map(
        (word) => word.word_index,
      );
      // create html string
      for (let i = 0; i < startIndex; i += 1) {
        highlightWord(!missedWordIdxs.includes(i), i);
      }
    }
    // eslint-disable-next-line
  }, []);

  const highlightWord = (isCorrect: boolean, wordIndex: number) => {
    // console.log(`highlight ${wordIndex} ${isCorrect}`);
    const wordNode = containerRef.current?.querySelector(`#word-${wordIndex}`);
    if (wordNode) {
      if (wordNode.className !== 'brw current') {
        // this means it has already been read
        return;
      }
      wordNode.className = `brw${isCorrect ? ' read' : ' incorrect'}`;
    }

    const nextWordNode = containerRef.current?.querySelector(
      `#word-${wordIndex + 1}`,
    );
    if (nextWordNode) {
      nextWordNode.className = `brw current`;
    }

    setWordIndex(wordIndex);
  };

  const autosave = (wordIndex: number) => {
    timer.clear(autosaveTimerKey);
    if (onStopReading) onStopReading(wordIndex);
  };

  const resetTimer = useCallback(() => {
    const timeThresholdExceeded = () => {
      timer.clear(missedWordTimerKey);
      setWordIndex((prev) => {
        highlightWord(false, prev);
        if (showMissedWordPopup) {
          setReadingState(ReadingState.MissedWord);
        } else {
          resetTimer();
        }
        return prev + 1;
      });
    };

    timer.clear(missedWordTimerKey);
    timer.start(
      missedWordTimerKey,
      () => timeThresholdExceeded(),
      missedWordInterval * 1000,
    );
  }, [timer, missedWordTimerKey, missedWordInterval, showMissedWordPopup]);

  const check = useCallback(
    (transcript: string[]) => {
      /*
      count the word as correct if any word in the transcript is similar to
      this current word  or if the current word is <= 3 characters and not a number
      and the most recent word in the transcript is the next word
    */
      if (!words || wordIndex >= words.length) return;
      let wordIdx = wordIndex;
      for (let i = 0; i < transcript.length; i++) {
        const currentWord = words[wordIdx];
        const currentWordIdx = wordIdx;
        console.log(transcript, currentWord);
        if (
          transcript.some((mw) =>
            ReadingUtils.compare(mw, currentWord, SpeechMode.Close),
          ) ||
          (transcript.length >= 2 &&
            ReadingUtils.compare(
              `${transcript.at(-2)}${transcript.at(-1)}`,
              currentWord,
              SpeechMode.Close,
            ))
        ) {
          highlightWord(true, currentWordIdx);
          wordIdx += 1;
        } else if (
          currentWordIdx < words.length + 1 &&
          currentWord.length <= 3 &&
          isNaN(parseFloat(currentWord)) &&
          isNaN(Number(currentWord)) &&
          transcript.some((mw) =>
            ReadingUtils.compare(
              mw,
              words[currentWordIdx + 1],
              SpeechMode.Close,
            ),
          )
        ) {
          highlightWord(true, currentWordIdx);
          highlightWord(true, currentWordIdx + 1);
          wordIdx += 2;
        }
      }
    },
    [wordIndex, words],
  );

  useEffect(() => {
    const unsubscribe = speechRecognitionService.subscribe(check);

    return () => {
      unsubscribe();
    };
  }, [check]);

  const handleStartReading = () => {
    setIsReading(true);
    speechRecognitionService.startSpeechRecognition();
    // transcriptionService.startTranscription();
    resetTimer();
    setReadingState(ReadingState.Reading);
    timer.start(autosaveTimerKey, () => autosave(wordIndex), 15000);
  };

  const handleStopReading = () => {
    setIsReading(false);
    speechRecognitionService.stopSpeechRecognition();
    // transcriptionService.stopTranscription();
    timer.clear(missedWordTimerKey);
    speechRecognitionService.clearTranscript();
    if (onStopReading) onStopReading(wordIndex);
    timer.clear(autosaveTimerKey);
    timer.clear(missedWordTimerKey);
    setReadingState(ReadingState.Paused);
  };

  const handleRetry = () => {
    setWordIndex(0);
    setReadingState(ReadingState.Reading);
  };

  return (
    <>
      {readingState !== ReadingState.MissedWord ? (
        <ReadingFrame
          isBlurred={
            false &&
            onCompletion &&
            !isReading &&
            readingState !== ReadingState.Completed
          }
          isDyslexic={dyslexicMode}
          refLink={containerRef}
        >
          <>
            {parse(htmlContent)}
            {readingState === ReadingState.Completed && (
              <div className="col" style={{ alignItems: 'center' }}>
                <img src={wormy_a} alt="" style={{ width: '150px' }} />
                <br />
                {customEndMessage && (
                  <span className="label-large">{customEndMessage}</span>
                )}
              </div>
            )}
          </>
        </ReadingFrame>
      ) : (
        showMissedWordPopup && (
          <MissedWordDisplay
            word={book.words![wordIndex - 1]}
            index={wordIndex - 1}
            onExit={handleStartReading}
            onMissedWord={onMissedWord}
          />
        )
      )}
      <div className="reading-footer">
        {readingState === ReadingState.Paused && (
          <Button
            type="go"
            onClick={handleStartReading}
            text="Go"
            leadIcon={play}
            label="Go"
          />
        )}
        {readingState === ReadingState.Reading && (
          <Button
            type="default"
            onClick={handleStopReading}
            text="Stop"
            leadIcon={pause}
            label="Stop"
          />
        )}
        {!hideQuestions && (
          <div className="book-completed-text">
            {onViewQuestions && (
              <Button
                type="option"
                onClick={onViewQuestions}
                text="Questions"
                label="Questions"
              />
            )}
          </div>
        )}
        {readingState === ReadingState.Completed && hideQuestions ? (
          onCompletion ? (
            <>
              <div className="book-completed-text"></div>
              <div className="book-completed-text">
                <h2>You're Done</h2>
              </div>
              <div className="book-completed-text">
                {onViewQuestions && (
                  <Button
                    type="option"
                    onClick={onViewQuestions}
                    text="Questions"
                    label="Questions"
                  />
                )}
              </div>
            </>
          ) : (
            <Button
              onClick={handleRetry}
              text="retry"
              leadIcon={rotate}
              label="Retry"
            />
          )
        ) : null}
      </div>
      {!(
        true ||
        !onCompletion ||
        isReading ||
        readingState === ReadingState.Completed
      ) && (
        <div className="book-emoji">
          <img
            src={book.cover_image !== '' ? book.cover_image : wormy_b}
            alt=""
          />
        </div>
      )}
    </>
  );
};
