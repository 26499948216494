import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Book from 'models/Book';
import { BookReader } from 'view/components/common/BookReader';
import MissedWord from 'models/MissedWord';
import Modal from 'view/components/common/Modal';
import {
  QuestionViewerState,
  QuestionsViewer,
} from 'view/components/student/QuestionsViewer';
import StudentQuestion from 'models/StudentQuestion';
import { formatDate } from 'utils/utils';
import { AppPage } from 'view/components/common/AppPage';
import useApiCall from 'contexts/ApiCall';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import AppSidebar from 'view/components/common/Sidebar';
import contentService from 'services/contentService';
import ListeningAssessment from 'view/components/student/assignment/ListeningAssessment';
import SpeakingAssessment from 'view/components/student/assignment/SpeakingAssessment';
import { BookListener } from 'view/components/student/assignment/BookListener';
import LanguageSelector from 'view/components/common/LanguageSelector';
import AssignmentSettings from 'view/components/student/assignment/AssignmentSettings';
import FlashcardPractice from 'view/components/student/practice/FlashcardPractice';
import PronouncePractice from 'view/components/student/practice/PronouncePractice';
import MultipartAssignment from 'view/components/student/newcomers/MultipartAssignment';
import teacherService from 'services/teacherService';
import Assignment from 'models/Assignment';
import QuestionResponse from 'models/QuestionResponse';
import Question from 'models/Question';
import ActionButton from '../buttons/ActionButton';

import 'view/style/student/assignment.css';

const calendar: string = require('assets/icons/event.svg').default;
const eye: string = require('assets/icons/eye-solid.svg').default;

type AssignmentPreviewProps = {
  assignment: Assignment;
  onSelect?: (
    assignment: Assignment,
    book?: Book,
    questions?: Question[],
  ) => void;
};

export const AssignmentPreview: React.FC<AssignmentPreviewProps> = ({
  assignment,
  onSelect,
}) => {
  const [book, setBook] = useState<Book>();
  const [questions, setQuestions] = useState<StudentQuestion[]>();
  const [viewQuestions, setViewQuestions] = useState<boolean>(false);
  const [isReading, setIsReading] = useState<boolean>(false);
  const [useDyslexic, setUseDyslexic] = useState<boolean>(false);
  const [viewSettings, setViewSettings] = useState<boolean>(false);
  const [assignmentWords, setAssignmentWords] = useState<MissedWord[]>();
  const pageContent = contentService.getAssignmentSubmissionPage();
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (!assignment) return;
    makeApiCall(teacherService.getAssignmentById, assignment.id)
      .then((respAssignment) => {
        console.log(respAssignment);
        setBook(respAssignment.book);
        setQuestions(
          respAssignment.questions.map((q: Question) => {
            return {
              question: q,
              response: QuestionResponse.generateDefault(),
            };
          }),
        );
      })
      .catch((error) => navigate(-1));
  }, [assignment, assignment.id, setBook, setQuestions, makeApiCall, navigate]);

  useEffect(() => {
    if (assignmentWords) return;
    if (assignment.key_words) {
      setAssignmentWords(
        assignment.key_words.map(
          (kw) => new MissedWord('-1', kw, '-1', 0, false, true),
        ),
      );
    } else {
      setAssignmentWords(
        ['words', 'vary', 'by', 'student'].map(
          (kw) => new MissedWord('-1', kw, '-1', 0, false, true),
        ),
      );
    }
  }, [assignment.key_words, assignmentWords, setAssignmentWords]);

  const handleCloseQuestions = () => {
    setViewQuestions(false);
  };

  const handleViewQuestions = () => {
    setViewQuestions(true);
  };

  if (!assignment) return <AppPage />;

  return (
    <div className="app-page-content">
      {!isReading && (
        <AppSidebar
          onExit={() => navigate(-1)}
          onViewSettings={() => setViewSettings(true)}
        >
          <>
            <div className="sidebar-content-data">
              <div className="row">
                <img src={calendar} alt="" />
                <label className="label-small">
                  {pageContent.sidebar.due.label}
                </label>
                <AudioAssistanceButton text="Due Date" />
              </div>
              <label className="label-large">
                {assignment.due_date
                  ? formatDate(new Date(assignment.due_date))
                  : 'No due date'}
              </label>
            </div>
            {assignment.start_date && (
              <div className="sidebar-content-data">
                <label className="label-small">
                  {pageContent.sidebar.available.label}{' '}
                  {formatDate(new Date(assignment.start_date))}
                </label>
              </div>
            )}
            <LanguageSelector />
            <div className="row spaced">
              <div className="row">
                <img src={eye} alt="" />
                <label className="label-small">Change to dyslexic font</label>
                <AudioAssistanceButton text="Change to dyslexic font" />
              </div>
              <input
                type="checkbox"
                checked={useDyslexic}
                onChange={() => setUseDyslexic((prev) => !prev)}
              />
            </div>
            {onSelect && (
              <ActionButton
                label="Create a copy"
                onClick={() =>
                  onSelect(
                    assignment,
                    book,
                    questions?.map((q) => q.question),
                  )
                }
              />
            )}
          </>
        </AppSidebar>
      )}
      <div className="app-main-content">
        <h1>{assignment?.title}</h1>
        {!book && !assignment && pageContent.error_messages.no_book}
        {book && assignment?.assignment_type === 'Default' && (
          <div className="reading-container">
            <BookReader
              book={book}
              startIndex={0}
              isReading={isReading}
              missedWordInterval={assignment.missed_word_interval}
              showMissedWordPopup={assignment.show_missed_word_popup}
              dyslexicMode={useDyslexic}
              setIsReading={setIsReading}
              onMissedWord={() => {}}
              onViewQuestions={
                questions && questions?.length > 0
                  ? handleViewQuestions
                  : undefined
              }
              hideQuestions={false}
            />
          </div>
        )}
        {book && assignment?.assignment_type === 'Read-Aloud' && (
          <BookListener
            book={book}
            isReading={isReading}
            dyslexicMode={useDyslexic}
            setIsReading={setIsReading}
            onViewQuestions={
              questions && questions?.length > 0
                ? handleViewQuestions
                : undefined
            }
            onMissedWord={() => {}}
          />
        )}
        {book && assignment?.assignment_type === 'Listening' && (
          <ListeningAssessment
            book={book}
            isReading={isReading}
            setIsReading={setIsReading}
            onStopReading={() => {}}
            onCompletion={() => {}}
            onViewQuestions={
              questions && questions?.length > 0
                ? handleViewQuestions
                : undefined
            }
          />
        )}
        {book && assignment?.assignment_type === 'Speaking' && (
          <SpeakingAssessment
            book={book}
            isReading={isReading}
            setIsReading={setIsReading}
            onStopReading={() => {}}
            onCompletion={() => {}}
            onMissedWord={() => {}}
            onViewQuestions={
              questions && questions?.length > 0
                ? handleViewQuestions
                : undefined
            }
          />
        )}
        {assignment?.assignment_type === 'Flashcards' && assignmentWords && (
          <FlashcardPractice
            missedWords={assignmentWords}
            onProgress={() => {}}
          />
        )}
        {assignment?.assignment_type === 'Pronounce' && assignmentWords && (
          <PronouncePractice
            missedWords={assignmentWords}
            onProgress={() => {}}
          />
        )}
        {assignment?.assignment_type === 'Multi-Part' && questions && (
          <MultipartAssignment
            questions={questions}
            onUpdateResponse={() => {}}
          />
        )}
      </div>
      {questions && (
        <Modal isOpen={viewQuestions} onClose={handleCloseQuestions}>
          <QuestionsViewer
            state={QuestionViewerState.Locked}
            grade={0}
            questions={questions}
            onExit={handleCloseQuestions}
          />
        </Modal>
      )}
      <Modal isOpen={viewSettings} onClose={() => setViewSettings(false)}>
        <AssignmentSettings />
      </Modal>
    </div>
  );
};
