import React, { useState } from 'react';
import TeacherGradebookEntry from 'models/TeacherGradebookEntry';
import AssignmentSubmission from 'models/AssignmentSubmission';
import { useClassroomContext } from 'contexts/ClassroomContext';
import NumberSelect from 'view/components/common/NumberSelect';
import StudentProfile from 'models/StudentProfile';
import AssignmentSubmissionRow from 'view/components/teacher/classroom/AssignmentSubmissionRow';
import Assignment from 'models/Assignment';

import 'view/style/teacher/components/classroom/studentDetails.css';

const school: string = require('assets/icons/school_white.svg').default;

interface StudentDetailsProps {
  studentInfo: TeacherGradebookEntry;
  assignments: Assignment[];
  onUpdateAssignmentSubmission: (
    assignment: AssignmentSubmission,
    field: string,
    value: any,
  ) => void;
  onUpdateStudent: (
    studentProfile: StudentProfile,
    readingLevel: number,
  ) => void;
}

export const StudentDetails: React.FC<StudentDetailsProps> = ({
  studentInfo,
  assignments,
  onUpdateAssignmentSubmission,
  onUpdateStudent,
}) => {
  const { classroom } = useClassroomContext();
  const [selectedAssignment, setSelectedAssignment] = useState<string>();

  if (!classroom) return null;

  return (
    <div className="student-details">
      <div className="student-details-header">
        <div className="row spaced">
          <div className="row">
            <img src={school} alt="" />
            <label className="label-large">{studentInfo.displayName()}</label>
            <span className="label-small">({studentInfo.email})</span>
          </div>
          <div className="row" style={{ gap: '0px' }}>
            <label className="label-normal">Reading Group:</label>
            <NumberSelect
              initialValue={studentInfo.student_profile.reading_level}
              min={classroom?.min_reading_level}
              max={classroom?.max_reading_level}
              onChange={(value) =>
                onUpdateStudent(studentInfo.student_profile, value)
              }
            />
          </div>
        </div>
      </div>
      <div className="student-details-content">
        {studentInfo.assignment_submissions.map(
          (assignmentSubmission: AssignmentSubmission) => {
            const assignment = assignments?.find(
              (a) => Number(a.id) === Number(assignmentSubmission.assignment),
            );
            return (
              assignment && (
                <AssignmentSubmissionRow
                  key={`student-assignment-row-${assignment.getId()}`}
                  label={assignment.title}
                  assignmentSubmission={assignmentSubmission}
                  assignment={assignment}
                  selected={selectedAssignment === assignmentSubmission.getId()}
                  setSelected={setSelectedAssignment}
                  onUpdateAssignmentSubmission={onUpdateAssignmentSubmission}
                />
              )
            );
          },
        )}
      </div>
    </div>
  );
};
