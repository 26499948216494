import React, { ReactNode } from 'react';

import 'view/style/student/components/assignment/imageChoice.css';

interface ImageChoiceProps {
  id: string;
  selected?: boolean;
  onClick: () => void;
  onDoubleClick: () => void;
  children: ReactNode;
  highlight?: boolean;
}

const ImageChoice: React.FC<ImageChoiceProps> = ({
  id,
  selected,
  onClick,
  onDoubleClick,
  children,
  highlight,
}) => {
  return (
    <div
      id={id}
      key={id}
      className={`image-choice${selected ? ' selected' : ''}${
        highlight ? ' highlighted' : ''
      }`}
      onClick={selected ? onDoubleClick : onClick}
    >
      {children}
    </div>
  );
};

export default ImageChoice;
