import React, { ReactNode } from 'react';

import 'view/style/common/components/itemPanel.css';

interface ItemPanelProps {
  id: string;
  selected?: boolean;
  expanded?: boolean;
  onClick: () => void;
  onDoubleClick: () => void;
  children: ReactNode;
  highlight?: boolean;
}

const ItemPanel: React.FC<ItemPanelProps> = ({
  id,
  selected,
  expanded,
  onClick,
  onDoubleClick,
  children,
  highlight,
}) => {
  return (
    <div
      id={id}
      key={id}
      className={`item-panel${selected ? ' selected' : ''}${
        expanded ? ' expanded' : ''
      }${highlight ? ' highlighted' : ''}`}
      onClick={selected ? onDoubleClick : onClick}
    >
      {children}
    </div>
  );
};

export default ItemPanel;
