export default class MultipleChoiceOption {
  public id: string;
  public question: string;
  public text: string;
  public correct: boolean;
  public img?: string;
  public img_file?: File;

  constructor(
    id: string,
    question: string,
    text: string,
    correct: boolean,
    img?: string,
    img_file?: File,
  ) {
    this.id = id;
    this.question = question;
    this.text = text;
    this.correct = correct;
    this.img = img;
    this.img_file = img_file;
  }

  static fromMultipleChoiceOption(question: MultipleChoiceOption) {
    return new MultipleChoiceOption(
      question.id,
      question.question,
      question.text,
      question.correct,
      question.img,
      question.img_file,
    );
  }

  static fromServerOption(option: Record<string, any>): MultipleChoiceOption {
    return new MultipleChoiceOption(
      option['id'],
      option['question'],
      option['text'],
      option['correct'],
      option['img'],
    );
  }

  public toJSON(): Record<string, any> {
    return {
      id: this.id,
      question: this.question,
      text: this.text,
      correct: this.correct,
      img_file: this.img_file,
      img: this.img,
    };
  }
}
