import Book from 'models/Book';
import React, { useState } from 'react';
import AudioPlayer from 'view/components/common/AudioPlayer';
import BookCompletedFooter from './BookCompletedFooter';

import 'view/style/student/components/assignment/listeningAssessment.css';

const wormy_e = require('assets/images/logos/wormy-book-3.png');

interface ListeningAssessmentProps {
  book: Book;
  isReading: boolean;
  startTime?: number;
  setIsReading: (isReading: boolean) => void;
  onStopReading: (timeStamp: number, duration: number) => void;
  onCompletion: () => void;
  onViewQuestions?: () => void;
}

const ListeningAssessment: React.FC<ListeningAssessmentProps> = ({
  book,
  isReading,
  startTime = 0,
  setIsReading,
  onStopReading,
  onCompletion,
  onViewQuestions,
}) => {
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  const handleListeningCompleted = () => {
    setIsCompleted(true);
    onCompletion();
  };

  return (
    <div className="reading-container">
      <div className="reading-frame listening-assessment">
        <img
          src={book.cover_image === '' ? wormy_e : book.cover_image}
          alt=""
        />
      </div>
      {isCompleted ? (
        <BookCompletedFooter onViewQuestions={onViewQuestions} />
      ) : (
        <AudioPlayer
          audioUrl={book.audio_url}
          onAudioEnded={handleListeningCompleted}
          onAudioPaused={onStopReading}
          startTime={startTime}
        />
      )}
    </div>
  );
};

export default ListeningAssessment;
