import React, { useState } from 'react';
import { DefinitionType, DictionaryDefinition } from 'utils/types';
import { AudioAssistanceButton } from '../buttons/AudioAssistanceButton';
import dictionaryService from 'services/dictionaryService';

type WordDefinitionProps = {
  definition?: DictionaryDefinition;
};

const WordDefinition: React.FC<WordDefinitionProps> = ({ definition }) => {
  const [definitionType, setDefinitionType] = useState<DefinitionType>(
    DefinitionType.DEFINITION,
  );

  const handleChangeDisplayType = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { value } = event.target;
    if (Object.values(DefinitionType).includes(value as DefinitionType)) {
      setDefinitionType(value as DefinitionType);
    }
  };

  if (!definition)
    return <span className="book-text">No definition found</span>;

  return (
    <>
      <select
        style={{ alignSelf: 'center', flex: '0 0 0', border: 'none' }}
        onChange={handleChangeDisplayType}
        value={definitionType}
      >
        <option value={DefinitionType.DEFINITION}>Definition</option>
        <option value={DefinitionType.SYNONYM}>Synonyms</option>
        <option value={DefinitionType.ANTONYM}>Antonyms</option>
      </select>
      <div className="missed-word-definition">
        {definitionType === DefinitionType.DEFINITION && (
          <span className="book-text">
            <div className="row">
              <h5>{definition.definition}</h5>
              <AudioAssistanceButton
                text={definition.definition ?? 'No definition found'}
                lang={dictionaryService.language.code}
              />
            </div>
          </span>
        )}
        {definitionType === DefinitionType.SYNONYM && (
          <span className="book-text">
            {definition?.synonyms && <h5>{definition.synonyms.join(', ')}</h5>}
          </span>
        )}
        {definitionType === DefinitionType.ANTONYM && (
          <span className="book-text">
            {definition?.antonyms && <h5>{definition.antonyms.join(', ')}</h5>}
          </span>
        )}
      </div>
    </>
  );
};

export default WordDefinition;
