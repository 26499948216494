import React from 'react';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import ItemPanel from 'view/components/common/ItemPanel';
import MultipleChoiceOption from 'models/MultipleChoiceOption';

import 'view/style/student/components/newcomer/multipartAssignment.css';

type QuestionChoicesProps = {
  choice?: string;
  choices: MultipleChoiceOption[];
  highlightCorrect?: boolean;
  onUpdateResponse: (
    text: string,
    choice: string,
    correct: boolean,
    feedback?: string,
    submit?: boolean,
  ) => void;
};

const QuestionChoices: React.FC<QuestionChoicesProps> = ({
  choice,
  choices,
  highlightCorrect = false,
  onUpdateResponse,
}) => {
  return (
    <div className="item-list">
      {choices.map((c: MultipleChoiceOption) => (
        <ItemPanel
          key={`choice-${c.id}`}
          id={`choice-${c.id}`}
          selected={
            highlightCorrect ? c.correct : Number(choice) === Number(c.id)
          }
          onClick={() =>
            onUpdateResponse('', c.id, c.correct, undefined, false)
          }
          onDoubleClick={() =>
            onUpdateResponse('', c.id, c.correct, undefined, false)
          }
          highlight={c.correct && highlightCorrect}
        >
          <span className="label-medium">{c.text}</span>
          <AudioAssistanceButton text={c.text} />
        </ItemPanel>
      ))}
    </div>
  );
};

export default QuestionChoices;
