import MultipleChoiceOption from 'models/MultipleChoiceOption';
import Question from 'models/Question';
import React, { useEffect, useState } from 'react';
import ActionButton from 'view/components/buttons/ActionButton';
import IconButton from 'view/components/buttons/IconButton';
import { UserInput } from 'view/components/common/UserInput';
import { FileInput } from '../FileInputs';

const trash: string = require('assets/icons/delete_forever_red.svg').default;
const image: string = require('assets/icons/image_solid.svg').default;

type AssignmentQuestionProps = {
  question: Question;
  onQuestionTextChange: (question: Question, text: string) => void;
  onModifyQuestionChoice?: (
    question: Question,
    choice: MultipleChoiceOption,
    text: string,
    correct: boolean,
  ) => void;
  onDeleteQuestionChoice?: (
    question: Question,
    choice: MultipleChoiceOption,
  ) => void;
  onAddQuestionChoice: (question: Question) => void;
  onUpdateQuestion: (question: Question, submit?: boolean) => void;
  onDeleteQuestion: (question: Question) => void;
};

const AssignmentQuestion: React.FC<AssignmentQuestionProps> = ({
  question,
  onQuestionTextChange,
  onModifyQuestionChoice,
  onDeleteQuestionChoice,
  onAddQuestionChoice,
  onUpdateQuestion,
  onDeleteQuestion,
}) => {
  return (
    <>
      <UserInput
        key={`question-${question.index}`}
        id={`question-${question.index}`}
        label={`Question ${question.index}`}
        type="textarea"
        value={question.text}
        onChange={(event) => onQuestionTextChange(question, event.target.value)}
      />
      {question.choices && onModifyQuestionChoice && onDeleteQuestionChoice && (
        <MultipleChoiceOptionEditor
          question={question}
          choices={question.choices}
          onModifyQuestionChoice={onModifyQuestionChoice}
          onDeleteQuestionChoice={onDeleteQuestionChoice}
        />
      )}
      <div
        key={`question-${question.index}-buttons`}
        className="action-buttons"
      >
        <ActionButton
          type="go"
          onClick={() => onAddQuestionChoice(question)}
          label="Add choice"
        />
        <ActionButton
          type="edit"
          onClick={() => onUpdateQuestion(question, true)}
          label="Update"
        />
        <ActionButton
          type="delete"
          onClick={() => onDeleteQuestion(question)}
          label="Delete"
        />
      </div>
    </>
  );
};

export default AssignmentQuestion;

type MultipleChoiceOptionProps = {
  question: Question;
  choices: any[];
  onModifyQuestionChoice: (
    question: Question,
    choice: MultipleChoiceOption,
    text: string,
    correct: boolean,
  ) => void;
  onDeleteQuestionChoice: (
    question: Question,
    choice: MultipleChoiceOption,
  ) => void;
};

const MultipleChoiceOptionEditor: React.FC<MultipleChoiceOptionProps> = ({
  question,
  choices,
  onModifyQuestionChoice,
  onDeleteQuestionChoice,
}) => {
  const [isDefaultView, setIsDefaultView] = useState<boolean>(true);
  // this component should handle the teacher side for adding/modifying/deleting multiple
  // choice options for AssignmentQuestions for image based answers and test based answers
  useEffect(() => {
    const anyImgs = choices.some((choice) => choice.img);
    setIsDefaultView(!anyImgs);
  }, [choices, setIsDefaultView]);

  const handleChoiceImageUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    choice: any,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageAsString = e.target?.result;
        const updatedChoice = {
          ...choice,
          img: imageAsString?.toString(),
          img_file: file,
        };

        onModifyQuestionChoice(
          question,
          updatedChoice,
          updatedChoice.text,
          updatedChoice.correct,
        );
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      {isDefaultView ? (
        <div className="multiple-choice-options-default">
          {choices.map((choice, choiceIdx) => (
            <div key={`choice-${choiceIdx}`} className="multiple-choice-option">
              <input
                type="checkbox"
                checked={choice.correct}
                onChange={(event) =>
                  onModifyQuestionChoice(
                    question,
                    choice,
                    choice.text,
                    event.target.checked,
                  )
                }
              />
              <textarea
                value={choice.text}
                onChange={(event) =>
                  onModifyQuestionChoice(
                    question,
                    choice,
                    event.target.value,
                    choice.correct,
                  )
                }
              />
              <div
                className="action-buttons"
                style={{ width: '181px', maxWidth: '181px' }}
              >
                <FileInput
                  id={`choice-image-${question.index}-${choice.id}`}
                  label="Add Image"
                  accept="image/*"
                  type="inline"
                  icon={image}
                  onFileChange={(event) =>
                    handleChoiceImageUpload(event, choice)
                  }
                />
                <IconButton
                  icon={trash}
                  onClick={() => onDeleteQuestionChoice(question, choice)}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="multiple-choice-options-visual">
          {choices.map((choice, choiceIdx) => (
            <div key={`choice-${choiceIdx}`} className="multiple-choice-option">
              <div className="row">
                <input
                  type="checkbox"
                  checked={choice.correct}
                  onChange={(event) =>
                    onModifyQuestionChoice(
                      question,
                      choice,
                      choice.text,
                      event.target.checked,
                    )
                  }
                />
                <div className="multiple-choice-img">
                  <img src={choice.img} alt="" />
                  {/* <input
                    type="text"
                    value={choice.text}
                    onChange={(event) =>
                      onModifyQuestionChoice(
                        question,
                        choice,
                        event.target.value,
                        choice.correct,
                      )
                    }
                  /> */}
                </div>
                <div className="action-buttons">
                  <FileInput
                    id={`choice-image-${question.index}-${choice.id}`}
                    accept="image/*"
                    type="inline"
                    icon={image}
                    onFileChange={(event) =>
                      handleChoiceImageUpload(event, choice)
                    }
                  />
                  <IconButton
                    icon={trash}
                    onClick={() => onDeleteQuestionChoice(question, choice)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
