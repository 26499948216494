import apiService from 'services/apiService';
import Classroom from 'models/Classroom';
import TeacherGradebook from 'models/TeacherGradebook';
import Book from 'models/Book';
import Assignment from 'models/Assignment';
import Question from 'models/Question';
import AssignmentSubmission from 'models/AssignmentSubmission';
import {
  ServerMissedWords,
  Subscription,
  TeacherGeneratedStudent,
} from 'utils/types';
import StudentProfile from 'models/StudentProfile';
import { parseError, toServerDate } from 'utils/utils';
import User from 'models/User';
import { Cookies } from 'react-cookie';

export class TeacherService {
  private static instance: TeacherService;
  private gradebook?: TeacherGradebook;
  private cookies: Cookies;

  constructor() {
    this.cookies = new Cookies();
  }

  public static get(): TeacherService {
    if (!TeacherService.instance) {
      TeacherService.instance = new TeacherService();
    }

    return TeacherService.instance;
  }

  getCachedGradebook(): TeacherGradebook | undefined {
    return this.gradebook;
  }

  getGradebook = async (classroom_id: string): Promise<TeacherGradebook> => {
    try {
      const response = await apiService.api.get(
        `/classrooms/${classroom_id}/gradebook/`,
      );

      this.gradebook = TeacherGradebook.fromServerGradebook(response.data);
      return this.gradebook;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  createClassroom = async (classroom: Classroom): Promise<Classroom> => {
    try {
      const response = await apiService.api.post('/classrooms/', {
        ...classroom,
      });

      return Classroom.fromServerClassroom(response.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  createAssignment = async (
    assignment: Assignment,
    questions: Question[],
    book_id?: string | number,
  ): Promise<Assignment> => {
    try {
      const response = await apiService.api.post(
        '/assignments/',
        {
          ...assignment,
          book: book_id,
          questions: questions.map((q) => q.toJSON()),
          start_date: assignment.start_date
            ? toServerDate(assignment.start_date)
            : null,
          due_date: assignment.due_date
            ? toServerDate(assignment.due_date)
            : null,
          google_access_token: this.cookies.get('googleAccessToken'),
          google_refresh_token: this.cookies.get('googleRefreshToken'),
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      return Assignment.fromServerAssignment(response.data.assignment);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  createBook = async (
    book: Book,
    coverImageFile?: File,
    generateAudio?: boolean,
    shouldAnalyze: boolean = true,
  ): Promise<Book> => {
    try {
      const response = await apiService.api.post(
        '/books/',
        {
          ...book.toJSON(),
          cover_image_file: coverImageFile,
          generate_audio: generateAudio,
          should_analyze: shouldAnalyze,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return Book.fromServerBook(response.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getClassroomAssignments = async (
    classroom: Classroom,
  ): Promise<Assignment[]> => {
    try {
      const response = await apiService.api.get(
        `/classrooms/${classroom.getId()}/assignments/`,
      );

      return response.data.map((respAssignment: Record<string, any>) =>
        Assignment.fromServerAssignment(respAssignment),
      );
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getAssignmentById = async (assignment_id: string): Promise<any> => {
    try {
      const response = await apiService.api.get(
        `/assignments/${assignment_id}/`,
      );
      var book = Book.fromServerBook(response.data.book);
      var assignment = Assignment.fromServerAssignment(
        response.data.assignment,
      );

      if (assignment.assignment_type === 'Multi-Part') {
        await book.getJsonContent();
      } else {
        await book.getHtmlContent();
      }

      return {
        assignment: assignment,
        book: book,
        questions: response.data.questions.map((respQ: Record<string, any>) =>
          Question.fromServerQuestion(respQ),
        ),
      };
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  updateAssignment = async (
    assignment: Assignment,
    questions: Question[],
    book?: Book,
    coverImageFile?: File,
    generateAudio?: boolean,
  ): Promise<Assignment> => {
    try {
      const response = await apiService.api.put(
        `/assignments/${assignment.getId()}/`,
        {
          ...assignment,
          start_date: assignment.start_date
            ? toServerDate(assignment.start_date)
            : null,
          due_date: assignment.due_date
            ? toServerDate(assignment.due_date)
            : null,
          google_access_token: this.cookies.get('googleAccessToken'),
          google_refresh_token: this.cookies.get('googleRefreshToken'),
        },
      );
      if (book) {
        await apiService.api.put(
          `/books/${book.id}/`,
          {
            ...book,
            cover_image_file: coverImageFile,
            generate_audio: generateAudio,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
      }
      for (let question of questions) {
        console.log(question);
        await apiService.api.put(
          `/assignmentquestions/${question.getId()}/`,
          {
            ...question,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
      }
      return Assignment.fromServerAssignment(response.data.assignment);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  updateAssignmentSubmission = async (
    assignment_submission: AssignmentSubmission,
  ): Promise<AssignmentSubmission> => {
    try {
      const response = await apiService.api.patch(
        `/submissions/${assignment_submission.id}/`,
        {
          ...assignment_submission,
          google_access_token: this.cookies.get('googleAccessToken'),
          google_refresh_token: this.cookies.get('googleRefreshToken'),
        },
      );

      return AssignmentSubmission.fromServerAssignmentSubmission(response.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  createQuestion = async (
    text: string,
    index: number,
    assignment: Assignment,
    choices: any[],
  ): Promise<Question> => {
    try {
      const response = await apiService.api.post(
        `/assignmentquestions/`,
        {
          text: text,
          index: index,
          assignment: assignment.id,
          choices: choices?.map((c) => c.toJSON()),
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      return Question.fromServerQuestion(response.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  updateQuestion = async (question: Question): Promise<Question> => {
    try {
      const response = await apiService.api.put(
        `/assignmentquestions/${question.getId()}/`,
        {
          ...question.toJSON(),
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      return Question.fromServerQuestion(response.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  deleteQuestion = async (question: Question): Promise<boolean> => {
    try {
      await apiService.api.delete(`/assignmentquestions/${question.getId()}/`);
      return true;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getClassroomMissedWords = async (
    classroom: Classroom,
  ): Promise<ServerMissedWords> => {
    try {
      const response = await apiService.api.get(
        `/classrooms/${classroom.getId()}/missed_words/`,
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  updateClassroom = async (classroom: Classroom): Promise<Classroom> => {
    try {
      const response = await apiService.api.put(
        `/classrooms/${classroom.getId()}/`,
        { ...classroom },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  deleteClassroom = async (classroom: Classroom): Promise<boolean> => {
    try {
      await apiService.api.delete(`/classrooms/${classroom.getId()}/`);
      return true;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  updateStudentProfile = async (
    student: StudentProfile,
  ): Promise<StudentProfile> => {
    try {
      const response = await apiService.api.put(
        `/students/${student.getId()}/`,
        { ...student },
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  deleteAssignment = async (assignmentId: string): Promise<boolean> => {
    try {
      await apiService.api.delete(`/assignments/${assignmentId}/`);
      return true;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  deleteStudent = async (student_id: string): Promise<boolean> => {
    try {
      await apiService.api.delete(`/students/${student_id}/`);
      return true;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  searchStudents = async (searchTerm: string) => {
    try {
      const response = await apiService.api.get(
        `/users/?search=${searchTerm}&account_type=Student`,
      );
      return response.data.map((respUser: Record<string, any>) =>
        User.fromServerUser(respUser),
      );
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  addStudent = async (
    userId: string,
    classroomId: string,
    readingLevel: number,
  ): Promise<User> => {
    try {
      const response = await apiService.api.post(
        `/classrooms/${classroomId}/add-student/`,
        { user: userId, reading_level: readingLevel },
      );
      return User.fromServerUser(response.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  createStudent = async (
    student: TeacherGeneratedStudent,
    classroomId: string,
    subscription: Subscription,
  ): Promise<User> => {
    try {
      const userResponse = await apiService.api.post('/users/', {
        first_name: student.firstName,
        last_name: student.lastName,
        email: student.email,
        username: student.username,
        password: student.password,
        subscription_id: subscription.id.toString(),
      });
      const studentResponse = this.addStudent(
        userResponse.data.user.id,
        classroomId,
        student.readingLevel,
      );
      return studentResponse;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  createPracticeAssignment = async (assignment: Assignment): Promise<any> => {
    try {
      const response = await apiService.api.post('/assignments/', {
        ...assignment,
      });
      return Assignment.fromServerAssignment(response.data.assignment);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  updatePracticeAssignment = async (
    assignmentId: string,
    assignment: Assignment,
  ): Promise<any> => {
    try {
      const response = await apiService.api.put(
        `/assignments/${assignmentId}/`,
        {
          ...assignment,
        },
      );
      return Assignment.fromServerAssignment(response.data.assignment);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };
}

const teacherService = TeacherService.get();
export default teacherService;
