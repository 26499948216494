import React, { useState } from 'react';
import { WordList } from './WordList';

const add: string = require('assets/icons/add_circle_outline.svg').default;

type WordAdderProps = {
  words: string[];
  onAddWord: (word: string) => void;
  onDeleteWord: (word: string) => void;
};

const WordAdder: React.FC<WordAdderProps> = ({
  words,
  onAddWord,
  onDeleteWord,
}) => {
  const [nextWord, setNextWord] = useState<string>('');

  const handleAddWord = () => {
    setNextWord('');
    onAddWord(nextWord);
  };

  return (
    <>
      <div className="row stretch">
        <input
          type="text"
          placeholder="Enter a word..."
          value={nextWord}
          onChange={(event) => setNextWord(event.target.value)}
        />
        <img src={add} alt="plus" onClick={handleAddWord} />
      </div>
      <WordList words={Array.from(words)} onDeleteWord={onDeleteWord} />
    </>
  );
};

export default WordAdder;
