import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';
import userAuthService from 'services/userAuthService';
import useApiCall from 'contexts/ApiCall';
import { isValidUrl } from 'utils/utils';
import cleverService from 'services/cleverService';
import LoginForm from 'view/components/auth/login/LoginForm';
import googleApiService from 'services/googleAPIService';

import 'view/style/home/login.css';
import 'view/style/home/home.css';

enum LoginType {
  NATIVE = 1, // user clicks login button
  REDIRECT = 2, // user generically redirected to login
  CLEVER = 3, // user redirected to login via Clever
  CLEVERNOACCOUNT = 4, // user redirected to login via Clever, no account found for the user
  GOOGLE = 5, // user clicks sign in with Google
}

const checkLoginType = (searchParams: URLSearchParams) => {
  if (
    searchParams.has('cleverId') &&
    searchParams.get('cleverId') !== 'undefined' &&
    searchParams.has('cleverToken') &&
    searchParams.get('cleverToken') !== 'undefined'
  ) {
    return LoginType.CLEVERNOACCOUNT;
  } else if (
    searchParams.has('accessToken') &&
    searchParams.get('accessToken') !== 'undefined' &&
    searchParams.has('refreshToken') &&
    searchParams.get('refreshToken') !== 'undefined' &&
    searchParams.has('cleverToken') &&
    searchParams.get('cleverToken') !== 'undefined'
  ) {
    return LoginType.CLEVER;
  } else if (
    searchParams.has('accessToken') &&
    searchParams.get('accessToken') !== 'undefined' &&
    searchParams.has('refreshToken') &&
    searchParams.get('refreshToken') !== 'undefined' &&
    searchParams.has('googleAccessToken') &&
    searchParams.get('googleAccessToken') !== 'undefined' &&
    searchParams.has('googleRefreshToken') &&
    searchParams.get('googleRefreshToken') !== 'undefined'
  ) {
    return LoginType.GOOGLE;
  } else if (
    searchParams.has('accessToken') &&
    searchParams.get('accessToken') !== 'undefined' &&
    searchParams.has('refreshToken') &&
    searchParams.get('refreshToken') !== 'undefined'
  ) {
    return LoginType.REDIRECT;
  } else {
    return LoginType.NATIVE;
  }
};

export type LoginMetadata = {
  clever_id?: string;
  district_clever_id?: string;
};

export const LoginPage: React.FC = () => {
  // const [loginType, setLoginType] = useState<LoginType>(LoginType.NATIVE);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [infoMessage, setInfoMessage] = useState<string>();
  const [metadata, setMetadata] = useState<LoginMetadata>({});
  const { user, setUser } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();
  const makeApiCall = useApiCall();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const loginType: LoginType = checkLoginType(searchParams);
    // setLoginType(loginType);

    var accessToken: string = 'undefined';
    var refreshToken: string = 'undefined';
    var cleverToken: string = 'undefined';

    switch (loginType) {
      case LoginType.NATIVE:
        return;
      case LoginType.REDIRECT:
        accessToken = searchParams.get('accessToken')!;
        refreshToken = searchParams.get('refreshToken')!;
        break;
      case LoginType.CLEVER:
        accessToken = searchParams.get('accessToken')!;
        refreshToken = searchParams.get('refreshToken')!;
        cleverToken = searchParams.get('cleverToken')!;
        cleverService.saveToken(cleverToken);
        break;
      case LoginType.CLEVERNOACCOUNT:
        cleverToken = searchParams.get('cleverToken')!;
        setMetadata((prev) => {
          return { ...prev, clever_id: searchParams.get('cleverId')! };
        });
        cleverService.saveToken(cleverToken);
        setInfoMessage(
          'If you aleady have an Orator account, login to link your Clever account. Otherwise, create an account by clicking the link below.',
        );
        makeApiCall(cleverService.getUserInfo, searchParams.get('cleverId'))
          .then((resp) => {
            setMetadata((prev) => {
              return { ...prev, district_clever_id: resp.data.district };
            });
          })
          .catch((error) => alert(error));
        return;
      case LoginType.GOOGLE:
        accessToken = searchParams.get('accessToken')!;
        refreshToken = searchParams.get('refreshToken')!;
        const googleAccessToken = searchParams.get('googleAccessToken')!;
        const googleRefreshToken = searchParams.get('googleRefreshToken')!;
        googleApiService.saveTokens(googleAccessToken, googleRefreshToken);
        break;
      default:
        alert('Unknown login source');
        navigate('/');
    }

    userAuthService.authenticate(accessToken, refreshToken);
    makeApiCall(userAuthService.getSessionUser)
      .then((respUser) => setUser(respUser))
      .catch((error: Error) => {
        if (isValidUrl(error.message)) {
          window.location.href = error.message;
        } else {
          setErrorMessage(error.message);
        }
      });
  }, [location, setUser, navigate, makeApiCall]);

  useEffect(() => {
    if (!userAuthService.isAuthenticated()) return;
    if (user) {
      navigate(`/${user.account_type.toLowerCase()}/home`);
    } else {
      makeApiCall(userAuthService.getSessionUser)
        .then((respUser) => setUser(respUser))
        .catch((error: Error) => {
          if (isValidUrl(error.message)) {
            window.location.href = error.message;
          } else {
            setErrorMessage(error.message);
          }
        });
    }
  }, [user, navigate, setUser, makeApiCall]);

  const handleLogin = (username: string, password: string) => {
    makeApiCall(userAuthService.loginUser, username, password, metadata)
      .then((authUser) => setUser(authUser))
      .catch((error: Error) => {
        if (isValidUrl(error.message)) {
          window.location.href = error.message;
        } else {
          setErrorMessage(error.message);
        }
      });
  };

  const generateSignupAddendum = () => {
    if (metadata.clever_id) {
      return `/?cleverId=${metadata.clever_id}`;
    }

    return '';
  };

  useEffect(() => {
    googleApiService.initializeGoogleAuth(googleLoginCallback);
    // eslint-disable-next-line
  }, []);

  const googleLoginCallback = (response: any) => {
    googleApiService.loginCallback(response);
  };

  return (
    <div className="login-page">
      <LoginForm
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        onLogin={handleLogin}
        signupAddendum={generateSignupAddendum()}
      />
      {/* {(loginType === LoginType.CLEVER ||
        loginType === LoginType.NATIVE ||
        loginType === LoginType.REDIRECT) && (
        <LoginForm errorMessage={errorMessage} onLogin={handleLogin} />
      )}
      {loginType === LoginType.CLEVERNOACCOUNT && <MergeAccountForm />} */}
    </div>
  );
};
