import { parseError } from 'utils/utils';
import apiService from 'services/apiService';
import dictionaryService from './dictionaryService';

export class AIService {
  private static instance: AIService;

  public static get(): AIService {
    if (!AIService.instance) {
      AIService.instance = new AIService();
    }

    return AIService.instance;
  }

  gradeShortAnswer = async (
    question: string,
    user_response: string,
  ): Promise<any[]> => {
    try {
      const response = await apiService.api.post(`/ai/grade-short-answer/`, {
        question: question,
        user_response: user_response,
        language: dictionaryService.language.name,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  generateNewcomerJson = async (
    topic: string,
    reading_difficulty: string,
    length: number,
  ): Promise<any[]> => {
    try {
      const response = await apiService.api.post(
        `/ai/generate-newcomer-json/`,
        {
          topic: topic,
          reading_difficulty: reading_difficulty,
          length: length,
        },
      );
      return response.data.content;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };
}

const aiService = AIService.get();
export default aiService;
