import MissedWord from 'models/MissedWord';
import React, { useEffect, useState } from 'react';
import HorizontalCarousel from 'view/components/common/HorizontalCarousel';
import { PracticePopup } from '../MissedWordPopup';
import useApiCall from 'contexts/ApiCall';
import dictionaryService from 'services/dictionaryService';
import Button from 'view/components/buttons/Button';

const play: string = require('assets/icons/play_circle_filled.svg').default;
const pause: string = require('assets/icons/pause_circle.svg').default;

interface PronouncePracticeProps {
  missedWords: MissedWord[];
  onProgress?: () => void;
  onCompletion?: () => void;
}

const PronouncePractice: React.FC<PronouncePracticeProps> = ({
  missedWords,
  onProgress,
  onCompletion,
}) => {
  const [wordCards, setWordCards] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const randomMissedWords = missedWords
      .sort(() => Math.random() - 0.5)
      .slice(0, 10);
    setWordCards(
      randomMissedWords.map((mw) => (
        <PronounceCard title="Try to say this!" word={mw.word} />
      )),
    );
  }, [missedWords]);

  return (
    <div className="practice-modal">
      <HorizontalCarousel pages={wordCards} onSubmit={onCompletion} />
    </div>
  );
};

export default PronouncePractice;

interface PronounceCardProps {
  title: string;
  word: string;
}

const PronounceCard: React.FC<PronounceCardProps> = ({ title, word }) => {
  const [definition, setDefinition] = useState<any>();
  const [isSpeaking, setIsSpeaking] = useState<boolean>(false);
  const makeApiCall = useApiCall();

  useEffect(() => {
    makeApiCall(dictionaryService.getWordDefinition, word)
      .then((respDef) => setDefinition(respDef ?? {}))
      .catch((error) => console.error(error.message));
  }, [word, makeApiCall]);

  return (
    <div className="missed-word-popup">
      <PracticePopup
        title={title}
        word={word}
        definition={definition}
        isSpeaking={isSpeaking}
        setIsFlipped={() => {}}
      />
      <Button
        type={isSpeaking ? 'delete' : 'go'}
        onClick={() => setIsSpeaking((prev) => !prev)}
        text={isSpeaking ? 'Stop' : 'Speak'}
        leadIcon={isSpeaking ? pause : play}
        label={isSpeaking ? 'Stop' : 'Speak'}
      />
    </div>
  );
};
