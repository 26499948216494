import { Grid } from '@mui/material';
import useApiCall from 'contexts/ApiCall';
import Book from 'models/Book';
import React, { useEffect, useState } from 'react';
import aiService from 'services/aiService';
import googleApiService from 'services/googleAPIService';
import CachingEngine from 'utils/CachingEngine';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import Button from 'view/components/buttons/Button';
import IconButton from 'view/components/buttons/IconButton';
import AudioRecorder from 'view/components/common/AudioRecorder';
import { BookReader } from 'view/components/common/BookReader';
import ReadingFrame from 'view/components/common/ReadingFrame';
import SpeechBubble from 'view/components/common/SpeechBubble';
import ImageChoice from '../ImageChoice';
import Question from 'models/Question';
import StudentQuestion from 'models/StudentQuestion';
import QuestionResponse from 'models/QuestionResponse';
import TranscribeFrame from './TranscribeFrame';
import FillInBlankFrame from './FillInBlankFrame';
import QuestionFrame from './QuestionFrame';

import 'view/style/student/components/newcomer/multipartAssignment.css';

const check = require('assets/icons/check-solid.svg').default;
const xMark = require('assets/icons/xmark-solid.svg').default;
const pencil = require('assets/icons/pencil.svg').default;
const wormy_a: string = require('assets/images/logos/wormy-1.png');

type MultiPartAssignmentProps = {
  questions: StudentQuestion[];
  onCompletion?: () => void;
  onUpdateResponse: (question: Question, response: QuestionResponse) => void;
};

const MultipartAssignment: React.FC<MultiPartAssignmentProps> = ({
  questions,
  onCompletion,
  onUpdateResponse,
}) => {
  const [mutableQuestions, setMutableQuestions] =
    useState<StudentQuestion[]>(questions);
  const [activeQuestionIdx, setActiveFrameIdx] = useState<number>(0);
  const [responses, setResponses] = useState<QuestionResponse[]>(
    questions.map((q) => q.response),
  );

  const handlePrevious = () => {
    setActiveFrameIdx((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setActiveFrameIdx((prev) =>
      Math.min(prev + 1, mutableQuestions.length - 1),
    );
  };

  const handleCheck = () => {
    if (
      !mutableQuestions ||
      mutableQuestions[activeQuestionIdx].question.check ||
      mutableQuestions[activeQuestionIdx].question.question_type ===
        'readAloud' ||
      (responses[activeQuestionIdx].choice === null &&
        responses[activeQuestionIdx].response === '')
    )
      return;

    const updatedQuestions = [...questions];
    updatedQuestions[activeQuestionIdx].question.check = true;
    setMutableQuestions(updatedQuestions);

    var updatedResponses = [...responses];
    updatedResponses[activeQuestionIdx] = QuestionResponse.fromQuestionResponse(
      updatedResponses[activeQuestionIdx],
    );
    updatedResponses[activeQuestionIdx].num_attempts += 1;

    setResponses(updatedResponses);

    if (
      updatedResponses.every((response) => response.num_attempts >= 1) &&
      onCompletion
    ) {
      onCompletion();
    }
  };

  const updateResponse = (
    response: QuestionResponse,
    resetCheck: boolean = true,
  ) => {
    console.log('updating');
    var updatedResponses = [...responses];
    updatedResponses[activeQuestionIdx] = response;
    setResponses(updatedResponses);

    if (response.completed && questions[activeQuestionIdx].question.check) {
      onUpdateResponse(questions[activeQuestionIdx].question, response);
    }

    if (!resetCheck) return;

    const updatedQuestions = [...questions];
    updatedQuestions[activeQuestionIdx].question.check = false;
    setMutableQuestions(updatedQuestions);
  };

  if (!mutableQuestions) return null;

  return (
    <div className="reading-container">
      <ReadingFrame>
        {mutableQuestions[activeQuestionIdx].question.question_type ===
          'choice' && (
          <QuestionFrame
            question={mutableQuestions[activeQuestionIdx].question}
            response={responses[activeQuestionIdx]}
            updateResponse={updateResponse}
          />
        )}
        {mutableQuestions[activeQuestionIdx].question.question_type ===
          'text' && (
          <QuestionFrame
            question={mutableQuestions[activeQuestionIdx].question}
            response={responses[activeQuestionIdx]}
            updateResponse={updateResponse}
          />
        )}
        {mutableQuestions[activeQuestionIdx].question.question_type ===
          'readAloud' && (
          <ReadAloudFrame
            question={mutableQuestions[activeQuestionIdx].question}
            response={responses[activeQuestionIdx]}
            updateResponse={updateResponse}
          />
        )}
        {mutableQuestions[activeQuestionIdx].question.question_type ===
          'conversation' && (
          <ConversationFrame
            question={mutableQuestions[activeQuestionIdx].question}
            response={responses[activeQuestionIdx]}
            updateResponse={updateResponse}
          />
        )}
        {mutableQuestions[activeQuestionIdx].question.question_type ===
          'visual' && (
          <VisualQuestionFrame
            question={mutableQuestions[activeQuestionIdx].question}
            response={responses[activeQuestionIdx]}
            updateResponse={updateResponse}
          />
        )}
        {mutableQuestions[activeQuestionIdx].question.question_type ===
          'transcribe' && (
          <TranscribeFrame
            question={mutableQuestions[activeQuestionIdx].question}
            response={responses[activeQuestionIdx]}
            updateResponse={updateResponse}
          />
        )}
        {mutableQuestions[activeQuestionIdx].question.question_type ===
          'respond' && (
          <ConversationFrame
            question={mutableQuestions[activeQuestionIdx].question}
            response={responses[activeQuestionIdx]}
            updateResponse={updateResponse}
          />
        )}
        {mutableQuestions[activeQuestionIdx].question.question_type ===
          'fillInBlank' && (
          <FillInBlankFrame
            question={mutableQuestions[activeQuestionIdx].question}
            response={responses[activeQuestionIdx]}
            updateResponse={updateResponse}
          />
        )}
      </ReadingFrame>
      <div className="row">
        {responses[activeQuestionIdx].num_attempts >= 1 ? (
          <img src={check} alt="Attempted" />
        ) : (
          <img src={pencil} alt="In progress" />
        )}
        {activeQuestionIdx > 0 && (
          <IconButton onClick={handlePrevious} icon="back" />
        )}
        <Button type="go" onClick={handleCheck} label="Submit" text="submit" />
        {activeQuestionIdx < responses.length - 1 &&
          responses[activeQuestionIdx].completed && (
            <IconButton onClick={handleNext} icon="next" />
          )}
        <label className="label-small">Question {activeQuestionIdx + 1}</label>
      </div>
    </div>
  );
};

export default MultipartAssignment;

export interface FrameProps {
  question: Question;
  response: QuestionResponse;
  updateResponse: (response: QuestionResponse, resetCheck?: boolean) => void;
}

const ReadAloudFrame: React.FC<FrameProps> = ({
  question,
  response,
  updateResponse,
}) => {
  const [book, setBook] = useState<Book>();
  const [missedWords, setMissedWords] = useState<string[]>([]);
  const [isReading, setIsReading] = useState<boolean>(false);

  useEffect(() => {
    var book = Book.generateDefault();
    // book.words = content.text.split(' ');
    book.html_content = `<p>${question.text}</p>`;
    setBook(book);
  }, [question.text]);

  const handleUpdateResponse = () => {
    const updatedResponse = QuestionResponse.fromQuestionResponse(response);
    updatedResponse.feedback = `${
      missedWords.length
    } words missed: ${missedWords.join(', ')}`;
    updatedResponse.grade = 100;
    updatedResponse.num_attempts += 1;
    updatedResponse.completed = true;

    updateResponse(updatedResponse);
  };

  return (
    <>
      {book && (
        <BookReader
          book={book}
          startIndex={0}
          isReading={isReading}
          setIsReading={setIsReading}
          onMissedWord={(word: string) =>
            setMissedWords((prev) => [...prev, word])
          }
          showMissedWordPopup={false}
          onCompletion={handleUpdateResponse}
        />
      )}
    </>
  );
};

const ConversationFrame: React.FC<FrameProps> = ({
  question,
  response,
  updateResponse,
}) => {
  // eslint-disable-next-line
  const [isReading, setIsReading] = useState<boolean>(false);
  const [audioSrc, setAudioSrc] = useState();
  const makeApiCall = useApiCall();

  useEffect(() => {
    // check the student's short answer response
    if (question.check && !question.choices) {
      makeApiCall(aiService.gradeShortAnswer, question.text, response.response)
        .then((resp) =>
          handleUpdateResponse(
            response.response,
            resp.correct,
            resp.feedback,
            false,
          ),
        )
        .catch((error) => alert(error));
    }

    if (question.text && !audioSrc) {
      CachingEngine.getData(`${question.index}-conversation`).then(
        (cachedAudio) => {
          if (cachedAudio) {
            setAudioSrc(cachedAudio);
          } else {
            makeApiCall(googleApiService.convertTextToSpeech, question.text)
              .then((resp) => {
                setAudioSrc(resp);
                CachingEngine.setData(`${question.index}-conversation`, resp);
              })
              .catch((error) => alert(error));
          }
        },
      );
    }

    // eslint-disable-next-line
  }, [question.check, question.text, makeApiCall]);

  const submitAudio = async (audioBlob: Blob) => {
    makeApiCall(googleApiService.convertSpeechToText, audioBlob)
      .then((resp) => handleUpdateResponse(resp, false))
      .catch((error) => alert(error));
  };

  const handleUpdateResponse = (
    responseText: string,
    correct: boolean,
    feedback?: string,
    isAttempt: boolean = true,
  ) => {
    const updatedResponse = QuestionResponse.fromQuestionResponse(response);
    updatedResponse.feedback = feedback;
    updatedResponse.grade = Number(correct) * 100;
    updatedResponse.num_attempts += Number(isAttempt);
    updatedResponse.completed = true;
    updatedResponse.response = responseText;

    updateResponse(updatedResponse);
  };

  return (
    <div className="conversation-frame">
      <div className="row">
        {question.question_type === 'conversation' ? (
          <label className="label-medium">{question.text}</label>
        ) : (
          <img className="wormy" src={wormy_a} alt="wormy" />
        )}
        <AudioAssistanceButton text={question.text} audioSrc={audioSrc} />
      </div>
      {response.response && <SpeechBubble text={response.response} />}
      <AudioRecorder
        onStartRecording={() => setIsReading(true)}
        onStopRecording={() => setIsReading(false)}
        onSubmit={submitAudio}
      />
      {response.completed && response.num_attempts > 0 && (
        <div className="row">
          <img src={response.grade > 0 ? check : xMark} alt="" />
          <label className="label-small">{response.feedback}</label>
          <AudioAssistanceButton text={response.feedback ?? ''} />
        </div>
      )}
    </div>
  );
};

const VisualQuestionFrame: React.FC<FrameProps> = ({
  question,
  response,
  updateResponse,
}) => {
  useEffect(() => {
    if (!question.check) return;
    if (question.choices && question.choices.length) {
      // update the response
      handleUpdateResponse(
        response.choice,
        response.grade > 0,
        response.feedback,
        true,
      );
    }
    // eslint-disable-next-line
  }, [question.check]);

  const handleUpdateResponse = (
    responseChoice: string,
    correct: boolean,
    feedback?: string,
    isAttempt: boolean = true,
  ) => {
    const updatedResponse = QuestionResponse.fromQuestionResponse(response);
    updatedResponse.feedback = feedback;
    updatedResponse.grade = Number(correct) * 100;
    updatedResponse.num_attempts += Number(isAttempt);
    updatedResponse.completed = response.completed || isAttempt;
    updatedResponse.choice = responseChoice;

    updateResponse(updatedResponse);
  };

  return (
    <div className="conversation-frame">
      <div className="question-input">
        <div className="row">
          <label className="label-medium">{question.text}</label>
          <AudioAssistanceButton text={question.text} />
        </div>
        {question.choices?.length && (
          <Grid container>
            {question.choices.map((choice) => (
              <Grid key={`image-choice-${choice.id}`} item>
                <ImageChoice
                  key={`choice-${choice.id}`}
                  id={`choice-${choice.id}`}
                  selected={
                    question.check
                      ? choice.correct
                      : Number(response.choice) === Number(choice.id)
                  }
                  onClick={() =>
                    response.num_attempts === 0 &&
                    !response.completed &&
                    handleUpdateResponse(
                      choice.id,
                      choice.correct,
                      response.feedback,
                      false,
                    )
                  }
                  onDoubleClick={() =>
                    response.num_attempts === 0 &&
                    !response.completed &&
                    handleUpdateResponse(
                      choice.id,
                      choice.correct,
                      response.feedback,
                      false,
                    )
                  }
                  highlight={choice.correct && question.check}
                >
                  <img src={choice.img} alt="" />
                </ImageChoice>
              </Grid>
            ))}
          </Grid>
        )}
        {question.choices && response.choice && question.check ? (
          <img src={response.grade > 0 ? check : xMark} alt="" />
        ) : null}
        {response.completed && (
          <div className="row">
            <img src={response.grade > 0 ? check : xMark} alt="" />
            <label className="label-small">{response.feedback}</label>
            <AudioAssistanceButton text={response.feedback ?? ''} />
          </div>
        )}
      </div>
    </div>
  );
};
