import { EventEmitter } from 'events';

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

export class SpeechRecognitionService {
  private recognition: any;
  private readonly emitter: EventEmitter = new EventEmitter();
  private isRunning: boolean = false;
  private static instance: SpeechRecognitionService;
  private static language: string = 'en-US';
  private transcript: Set<string> = new Set();

  public static get(): SpeechRecognitionService {
    if (!SpeechRecognitionService.instance) {
      SpeechRecognitionService.instance = new SpeechRecognitionService();
    }

    return SpeechRecognitionService.instance;
  }

  public static setLanguage(language: string) {
    SpeechRecognitionService.language = language;
  }

  public recognitionAvailable(): boolean {
    return SpeechRecognition !== undefined;
  }

  private recognitionOnStart = () => {
    console.log('mic started');
  };

  private recognitionOnResult = (event: SpeechRecognitionEvent) => {
    const result = Array.from(event.results)
      .map((result: SpeechRecognitionResult) => result[0])
      .map((result: SpeechRecognitionAlternative) =>
        result.transcript.toLowerCase(),
      )
      .map((result: string) => result.split(' '));

    // Create a new set with cleaned and lowercased words from the event results
    const transcript = new Set(
      Array.prototype.concat
        .apply([], result)
        .map((word) => word.toLowerCase().replace(/[^a-z0-9]/gi, ''))
        .slice(-6),
    );

    this.transcript = transcript;

    // Emit the updated transcript as an array
    this.emitter.emit('wordRecognized', Array.from(this.transcript));
  };

  private recognitionOnEnd = () => {
    console.log('mic stopped');
  };

  private recognitionOnError = (event: any) => {
    console.error(event);
  };

  constructor() {
    this.recognition = new SpeechRecognition();

    this.recognition.continuous = false;
    this.recognition.interimResults = true;
    this.recognition.lang = SpeechRecognitionService.language;
    this.recognition.maxAlternatives = 4;

    this.recognition.onstart = this.recognitionOnStart;
    this.recognition.onresult = this.recognitionOnResult;
    this.recognition.onend = this.recognitionOnEnd;
    this.recognition.onerror = this.recognitionOnError;
  }

  startSpeechRecognition() {
    if (this.isRunning) return;

    try {
      this.recognition.start();
      // keep restarting the mic when it stops
      this.recognition.onend = () => this.recognition.start();

      this.isRunning = true;
    } catch (error) {
      console.error(error);
    }
  }

  stopSpeechRecognition() {
    if (!this.isRunning) return;

    this.recognition.onend = this.recognitionOnEnd;
    this.recognition.stop();

    this.isRunning = false;
  }

  subscribe(subscriber: (transcript: string[]) => void) {
    this.emitter.on('wordRecognized', subscriber);
    console.log('subscribed');

    return () => {
      this.unsubscribe(subscriber);
    };
  }

  unsubscribe(subscriber: (transcript: string[]) => void) {
    console.log('unsubscribed');
    this.emitter.off('wordRecognized', subscriber);
  }

  clearTranscript() {
    this.transcript = new Set();
  }
}

const speechRecognitionService = SpeechRecognitionService.get();
export default speechRecognitionService;
