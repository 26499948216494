import StudentGradebookEntry from './StudentGradebookEntry';

export default class StudentGradebook {
  public entries: Array<StudentGradebookEntry>;
  public averages: Record<string, number>;

  constructor(
    entries: Array<StudentGradebookEntry>,
    averages: Record<string, number>,
  ) {
    this.entries = entries;
    this.averages = averages;
  }

  static fromStudentGradebook(studentGradebook: StudentGradebook) {
    return new StudentGradebook(
      studentGradebook.entries,
      studentGradebook.averages,
    );
  }

  static fromServerStudentGradebook(
    serverClassroom: Record<string, any>,
  ): StudentGradebook {
    return new StudentGradebook(
      serverClassroom['entries']
        .map((serverEntry: Record<string, any>) =>
          StudentGradebookEntry.fromServerEntry(serverEntry),
        )
        .sort((e1: StudentGradebookEntry, e2: StudentGradebookEntry) => {
          const date1Completed = e1.assignment_submission.date_completed
            ? new Date(e1.assignment_submission.date_completed).getTime()
            : 0;
          const date2Completed = e2.assignment_submission.date_completed
            ? new Date(e2.assignment_submission.date_completed).getTime()
            : 0;

          // Check if both have a completion date, neither have, or only one has it
          if (date1Completed && date2Completed) {
            // Both are completed, sort by due date, handling nulls
            // const dueDate1 = e1.assignment.due_date
            //   ? new Date(e1.assignment.due_date).getTime()
            //   : null;
            // const dueDate2 = e2.assignment.due_date
            //   ? new Date(e2.assignment.due_date).getTime()
            //   : null;

            // if (dueDate1 === null && dueDate2 === null) return 0; // Both are null, consider equal
            // if (dueDate1 === null) return 1; // e1's due_date is null, so it should come after
            // if (dueDate2 === null) return -1; // e2's due_date is null, so it should come after

            // // If both are non-null, compare by due date in descending order
            return date2Completed - date1Completed;
          } else if (!date1Completed && !date2Completed) {
            // Neither is completed, sort by due date, handling nulls
            const dueDate1 = e1.assignment.due_date
              ? new Date(e1.assignment.due_date).getTime()
              : null;
            const dueDate2 = e2.assignment.due_date
              ? new Date(e2.assignment.due_date).getTime()
              : null;

            if (dueDate1 === null && dueDate2 === null) return 0; // Both are null, consider equal
            if (dueDate1 === null) return 1; // e1's due_date is null, so it should come after
            if (dueDate2 === null) return -1; // e2's due_date is null, so it should come after

            // If both are non-null, compare by due date in descending order
            return dueDate2 - dueDate1;
          } else {
            // One is completed, one isn't. The one that is completed goes first
            return date1Completed ? 1 : -1;
          }
        }),
      serverClassroom['averages'],
    );
  }

  public updateEntry(entry: StudentGradebookEntry): StudentGradebook {
    // first just replace the existing entry with whichever values
    // were updated, then update the averages
    var newGradebook = StudentGradebook.fromServerStudentGradebook({
      entries: [
        ...this.entries.filter(
          (e) => e.assignment.getId() !== entry.assignment.getId(),
        ),
        entry,
      ],
      averages: {
        ...this.averages,
        grade: Math.round(
          (this.averages.grade * (this.entries.length - 1) +
            entry.assignment_submission.grade) /
            this.entries.length,
        ),
      },
    });
    return newGradebook;
  }

  public toJSON(): Record<string, any> {
    return { ...this };
  }
}
