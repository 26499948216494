import React, { useEffect, useState } from 'react';
import Button from 'view/components/buttons/Button';
import contentService from 'services/contentService';
import SpeechBubble from '../common/SpeechBubble';
import speechRecognitionService from 'services/speechRecognitionService';
import ReadingUtils from 'utils/ReadingUtils';
import { AudioAssistanceButton } from '../buttons/AudioAssistanceButton';
import IconButton from '../buttons/IconButton';
import WordDefinition from './WordDefinition';
import { DictionaryDefinition } from 'utils/types';

interface MissedWordPopupProps {
  word: string;
  index: number;
  showDefinition: boolean;
  definition: DictionaryDefinition;
  practiceMode: boolean;
  isSpeaking: boolean;
  onWordPromptSelect?: (word: string, index: number, known: boolean) => void;
}

const MissedWordPopup: React.FC<MissedWordPopupProps> = ({
  word,
  index,
  showDefinition,
  definition,
  practiceMode,
  isSpeaking = false,
  onWordPromptSelect = () => {},
}) => {
  const formContent = contentService.getStudentForms().missed_word_popup;
  const [isFlipped, setIsFlipped] = useState<boolean>(false);

  return (
    <div className={`missed-word-popup ${isFlipped ? 'flipped' : ''}`}>
      {!showDefinition && !practiceMode && (
        <PromptPopup
          title={formContent.title}
          word={word}
          index={index}
          definition={definition}
          promptText={formContent.content.word_known_prompt.text}
          noLabel={formContent.content.word_known_prompt.no_button.label}
          yesLabel={formContent.content.word_known_prompt.yes_button.label}
          setIsFlipped={setIsFlipped}
          onWordPromptSelect={onWordPromptSelect}
        />
      )}
      {showDefinition && !practiceMode && (
        <DefinitionPopup
          title={formContent.title}
          word={word}
          definition={definition}
          errorMessage={formContent.content.word_definition.error_message}
          flipped={isFlipped}
          setIsFlipped={setIsFlipped}
        />
      )}
      {practiceMode && (
        <PracticePopup
          title={formContent.title}
          word={word}
          definition={definition}
          isSpeaking={isSpeaking}
          setIsFlipped={setIsFlipped}
        />
      )}
    </div>
  );
};

export default MissedWordPopup;

interface DefinitionPopupProps {
  title: string;
  word: string;
  definition: any;
  errorMessage: string;
  flipped: boolean;
  setIsFlipped: (prev: boolean) => void;
}

const DefinitionPopup: React.FC<DefinitionPopupProps> = ({
  title,
  word,
  definition,
  errorMessage,
  flipped,
  setIsFlipped,
}) => {
  return (
    <>
      {definition?.image && (
        <IconButton icon="reverse" onClick={() => setIsFlipped(!flipped)} />
      )}
      <label className="label-large">{title}</label>
      <div className="back">
        <div
          className="missed-word-popup-content"
          style={{ visibility: !flipped ? 'hidden' : 'visible' }}
        >
          <img
            src={definition?.image}
            alt=""
            style={{ width: '100%', height: 'auto', maxHeight: '100%' }}
          />
        </div>
      </div>
      <div
        className="front"
        style={{ visibility: flipped ? 'hidden' : 'visible' }}
      >
        <div className="missed-word-popup-content">
          <label className="label-xl">
            {word +
              `${
                !definition?.translation || definition.translation === ''
                  ? ''
                  : ' (' + definition.translation + ')'
              }`}
          </label>
          <span className="book-text">{definition?.ipa ?? word}</span>
          <WordDefinition definition={definition} />
        </div>
      </div>
    </>
  );
};

interface PromptPopupProps {
  title: string;
  word: string;
  index: number;
  definition: DictionaryDefinition;
  promptText: string;
  yesLabel: string;
  noLabel: string;
  setIsFlipped: (prev: boolean) => void;
  onWordPromptSelect: (word: string, index: number, known: boolean) => void;
}

const PromptPopup: React.FC<PromptPopupProps> = ({
  title,
  word,
  index,
  definition,
  promptText,
  yesLabel,
  noLabel,
  setIsFlipped,
  onWordPromptSelect,
}) => {
  return (
    <>
      <IconButton icon="reverse" onClick={() => setIsFlipped(true)} />
      <label className="label-large">{title}</label>
      <div className="missed-word-popup-content">
        <label className="label-xl">
          {word +
            `${
              !definition?.translation || definition.translation === ''
                ? ''
                : ' (' + definition.translation + ')'
            }`}
        </label>
        <span className="book-text">{definition?.ipa ?? word}</span>
        <div className="word-known-prompt">
          <span className="label-large">{promptText}</span>
          <br />
          <div className="row">
            <Button
              text={yesLabel}
              onClick={() => onWordPromptSelect(word, index, true)}
              label={yesLabel}
            />
            <Button
              text={noLabel}
              onClick={() => onWordPromptSelect(word, index, false)}
              label={noLabel}
            />
          </div>
        </div>
      </div>
    </>
  );
};

interface PracticePopupProps {
  title: string;
  word: string;
  definition: any;
  isSpeaking: boolean;
  active?: boolean;
  setIsFlipped: (prev: boolean) => void;
}

export const PracticePopup: React.FC<PracticePopupProps> = ({
  title,
  word,
  definition,
  isSpeaking,
  active = true,
  setIsFlipped,
}) => {
  const [spokenWord, setSpokenWord] = useState<string>('');
  useEffect(() => {
    if (!active) return;
    const wordReceivedHandler = (transcript: string[]) => {
      if (transcript.length) {
        const micWord = transcript.at(-1) as string;
        if (
          ReadingUtils.soundsSimilar(word, micWord) ||
          (transcript.length >= 2 &&
            ReadingUtils.soundsSimilar(
              word,
              `${transcript.at(-2)}${transcript.at(-1)}`,
            ))
        ) {
          setSpokenWord(word);
        } else {
          setSpokenWord(micWord);
        }
      }
    };

    const unsubscribe = speechRecognitionService.subscribe(wordReceivedHandler);

    return () => {
      speechRecognitionService.stopSpeechRecognition();
      unsubscribe();
    };
    // eslint-disable-next-line
  }, [active]);

  useEffect(() => {
    if (!active) return;
    if (isSpeaking) speechRecognitionService.startSpeechRecognition();
    else speechRecognitionService.stopSpeechRecognition();
  }, [isSpeaking, active]);

  return (
    <>
      {/* <IconButton icon="reverse" onClick={() => setIsFlipped(true)} /> */}
      <label className="label-large">{title}</label>
      <div className="missed-word-popup-content">
        <div className="row">
          <label className="label-xl">
            {word +
              `${
                !definition?.translation || definition.translation === ''
                  ? ''
                  : ' (' + definition.translation + ')'
              }`}
          </label>
          <AudioAssistanceButton text={word} audioSrc={definition?.audio} />
        </div>
        <span className="book-text">{definition?.ipa ?? word}</span>
        <>
          <SpeechBubble text={spokenWord} correct={spokenWord === word} />
        </>
      </div>
    </>
  );
};
