import Book from 'models/Book';
import { parseError } from 'utils/utils';
import apiService from 'services/apiService';
import { DefaultQuestion } from 'view/components/admin/catalog/QuestionEditor';
import Question from 'models/Question';

interface AdminCatalogBooks {
  public_books: Book[];
  review_books: Book[];
}

export class AdminService {
  private static instance: AdminService;

  public static get(): AdminService {
    if (!AdminService.instance) {
      AdminService.instance = new AdminService();
    }

    return AdminService.instance;
  }

  getCatalogBooks = async (): Promise<AdminCatalogBooks> => {
    try {
      //   const cachedData = await CachingEngine.getData('catalog');
      //   if (cachedData) {
      //     return {
      //       public_books: cachedData.books.map((b: Book) => Book.fromServerBook(b)),
      //       review_books: cachedData.liked_books.map((b: Book) =>
      //         Book.fromServerBook(b),
      //       ),
      //     };
      //   }
      const response = await apiService.api.get('/books/admin');
      const catalog = {
        public_books: response.data.public.map(
          (respBook: Record<string, any>) => Book.fromServerBook(respBook),
        ),
        review_books: response.data.review.map(
          (respBook: Record<string, any>) => Book.fromServerBook(respBook),
        ),
      };

      // store the catalog
      //   await CachingEngine.setData('catalog', catalog);

      return catalog;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  generateDefaultQuestions = async (text: string): Promise<any> => {
    try {
      const response = await apiService.api.post('/books/generate_questions/', {
        text: text,
      });
      return response.data.result;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  createDefaultQuestion = async (question: DefaultQuestion): Promise<any> => {
    try {
      const response = await apiService.api.post('/default_questions/', {
        ...question,
      });
      return Question.fromServerQuestion(response.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  updateDefaultQuestion = async (
    question: Question,
    bookId: string,
  ): Promise<any> => {
    try {
      const response = await apiService.api.patch(
        `/default_questions/${question.id}/`,
        {
          ...question,
          choices: question.choices?.map((c) => c.toJSON()),
          book: bookId,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return Question.fromServerQuestion(response.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  deleteDefaultQuestion = async (question: DefaultQuestion): Promise<any> => {
    try {
      const response = await apiService.api.delete(
        `/default_questions/${question.id}`,
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  reviewCatalogBook = async (book: Book, accept: boolean): Promise<any> => {
    try {
      const response = await apiService.api.post(
        `/books/${book.id}/update_approval/`,
        { accept: accept },
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  createBook = async (
    book: Book,
    coverImageFile?: File,
    removeCover: boolean = false,
    directToCatalog: boolean = true,
  ): Promise<Book> => {
    try {
      const response = await apiService.api.post(
        '/books/',
        {
          ...book.toJSON(),
          cover_image_file: coverImageFile,
          remove_cover: removeCover,
          is_public: directToCatalog,
          is_under_review: !directToCatalog,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return Book.fromServerBook(response.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  updateBook = async (
    book: Book,
    coverImageFile?: File,
    removeCover: boolean = false,
  ): Promise<Book> => {
    try {
      const response = await apiService.api.put(
        `/books/${book.id}/`,
        {
          ...book.toJSON(),
          cover_image_file: coverImageFile,
          remove_cover: removeCover,
          is_public: true,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return Book.fromServerBook(response.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  deleteBook = async (bookId: string): Promise<any> => {
    try {
      const response = await apiService.api.delete(`/books/${bookId}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getBookById = async (bookId: string): Promise<Book> => {
    try {
      const response = await apiService.api.get(`/books/${bookId}`);
      return Book.fromServerBook(response.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };
}

export const adminService = AdminService.get();
