import useApiCall from 'contexts/ApiCall';
import QuestionResponse from 'models/QuestionResponse';
import StudentQuestion from 'models/StudentQuestion';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import aiService from 'services/aiService';
import Button from 'view/components/buttons/Button';
import { AppPage } from 'view/components/common/AppPage';
import LanguageSelector from 'view/components/common/LanguageSelector';
import AppSidebar from 'view/components/common/Sidebar';
import { UserInput } from 'view/components/common/UserInput';
import MultipartAssignment from 'view/components/student/newcomers/MultipartAssignment';

export const NewcomersPage: React.FC = () => {
  const [frames, setFrames] = useState<StudentQuestion[]>();
  const [topic, setTopic] = useState<string>();
  const [readingDifficulty, setReadingDifficulty] = useState<string>();
  const [length, setLength] = useState<number>(8);
  const navigate = useNavigate();
  const makeApiCall = useApiCall();

  const handleGenerateAssignment = () => {
    makeApiCall(
      aiService.generateNewcomerJson,
      topic,
      readingDifficulty,
      length,
    )
      .then((resp) => {
        setFrames(
          resp.map((q: Record<string, any>) => {
            return {
              question: q,
              response: QuestionResponse.generateDefault(),
            };
          }),
        );
      })
      .catch((error) => alert(error));
  };

  return (
    <AppPage>
      <div className="app-page-content">
        <AppSidebar onExit={() => navigate('/student/home')}>
          <>
            <UserInput
              type="text"
              id="topic"
              label="Topic"
              value={topic}
              onChange={(event) => setTopic(event.target.value)}
            />
            <UserInput
              type="text"
              id="topic"
              label="Reading difficulty"
              value={readingDifficulty}
              onChange={(event) => setReadingDifficulty(event.target.value)}
            />
            <UserInput
              type="number"
              id="topic"
              label="Length"
              value={length}
              onChange={(event) => setLength(event.target.value)}
            />
            <LanguageSelector />
            <Button
              onClick={handleGenerateAssignment}
              type="go"
              label="Generate"
              text="generate"
            />
          </>
        </AppSidebar>
        <div className="app-main-content">
          <h1>Language Practice</h1>
          {frames && (
            <MultipartAssignment
              questions={frames}
              onUpdateResponse={() => {}}
            />
          )}
        </div>
      </div>
    </AppPage>
  );
};
