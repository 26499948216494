import { useConfirmation } from 'contexts/ConfirmationContext';
import React from 'react';

import 'view/style/common/buttons.css';

type IconButtonType = 'transparent';

const icons: Record<string, string> = {
  settings: require('assets/icons/settings.svg').default,
  delete: require('assets/icons/delete_forever.svg').default,
  add: require('assets/icons/person_add_green.svg').default,
  confirm: require('assets/icons/check-solid.svg').default,
  cancel: require('assets/icons/xmark-solid.svg').default,
  reverse: require('assets/icons/rotate.svg').default,
  hide: require('assets/icons/eye-slash-solid-white.svg').default,
  back: require('assets/icons/arrow_back.svg').default,
  next: require('assets/icons/arrow_next.svg').default,
  plus: require('assets/icons/add_circle_outline.svg').default,
};

interface IconButtonProps {
  icon: string;
  type?: IconButtonType;
  disabled?: boolean;
  requiresConfirmation?: boolean;
  confirmationMessage?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  type,
  disabled,
  requiresConfirmation = false,
  confirmationMessage = '',
  onClick,
}) => {
  const { openModal } = useConfirmation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!onClick) return;
    if (requiresConfirmation) {
      event.preventDefault();
      event.stopPropagation();
      openModal(confirmationMessage, onClick);
    } else {
      onClick(event);
    }
  };

  const svgIcon = icons[icon] ?? icon;
  return (
    <button
      className={`icon-button ${type || ''}`}
      onClick={handleClick}
      disabled={disabled}
    >
      <img src={svgIcon} alt="" />
    </button>
  );
};

export default IconButton;
