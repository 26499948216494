import {
  GOOGLE_CLIENT_ID,
  SCOPES,
  GOOGLE_REDIRECT_URI,
} from 'constants/apiConstants';
import User from 'models/User';
import { parseError } from 'utils/utils';
import apiService from 'services/apiService';
import { Cookies } from 'react-cookie';

export class GoogleAPIService {
  private static instance: GoogleAPIService;
  private cookies: Cookies;

  constructor() {
    this.cookies = new Cookies();
  }

  public static get(): GoogleAPIService {
    if (!GoogleAPIService.instance) {
      GoogleAPIService.instance = new GoogleAPIService();
    }

    return GoogleAPIService.instance;
  }

  initializeGoogleAuth(callback: (response: any) => void) {
    if (typeof window.google !== 'undefined') {
      const google = window.google;
      google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: callback,
      });
      google.accounts.id.renderButton(
        document.getElementById('googleSigninDiv'),
        { theme: 'outline', size: 'large' },
      );
      // google.accounts.id.prompt();
    }
  }

  loginCallback(response: any) {
    const clientId = GOOGLE_CLIENT_ID;
    const redirectUri = GOOGLE_REDIRECT_URI;
    const scope = encodeURIComponent(SCOPES);
    const prompt = 'select_account';

    const url = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&access_type=offline&prompt=${prompt}`;

    window.location.href = url;
  }

  saveTokens(googleAccessToken: string, googleRefreshToken: string) {
    this.cookies.set('googleAccessToken', googleAccessToken, { path: '/' });
    this.cookies.set('googleRefreshToken', googleRefreshToken, { path: '/' });
  }

  isAuthenticated() {
    return (
      this.cookies.get('googleAccessToken')!! &&
      this.cookies.get('googleRefreshToken')!!
    );
  }

  getGoogleClassrooms = async (user: User): Promise<any[] | null> => {
    try {
      if (!this.isAuthenticated()) return null;

      const response = await apiService.api.post('/google/get-classrooms/', {
        google_access_token: this.cookies.get('googleAccessToken'),
        google_refresh_token: this.cookies.get('google_refresh_token'),
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  convertTextToSpeech = async (
    text: string,
    langCode: string = 'en-US',
  ): Promise<any> => {
    try {
      const response = await apiService.api.post(
        `/google/texttospeech/`,
        { text: text, langCode: langCode },
        { responseType: 'blob' },
      );

      // Create a Blob from the response data
      const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });

      // Create a URL for the Blob
      const audioUrl = URL.createObjectURL(audioBlob);
      return audioUrl;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  convertSpeechToText = async (
    audio: Blob,
    langCode: string = 'en-US',
  ): Promise<any> => {
    try {
      const formData = new FormData();
      formData.append('audio', audio);

      const response = await apiService.api.post(
        `/google/speechtotext/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      return response.data.transcript;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };
}

const googleApiService = GoogleAPIService.get();
export default googleApiService;
