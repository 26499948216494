import React, { useState } from 'react';
import AssignmentSubmission from 'models/AssignmentSubmission';
import { useClassroomContext } from 'contexts/ClassroomContext';
import AssignmentSubmissionRow from 'view/components/teacher/classroom/AssignmentSubmissionRow';
import TeacherGradebook from 'models/TeacherGradebook';
import Assignment from 'models/Assignment';

const school: string = require('assets/icons/school_white.svg').default;

interface AssignmentSubmissionsProps {
  assignment: Assignment;
  gradebook: TeacherGradebook;
  onUpdateAssignmentSubmission: (
    assignment: AssignmentSubmission,
    field: string,
    value: any,
  ) => void;
}

export const AssignmentSubmissions: React.FC<AssignmentSubmissionsProps> = ({
  assignment,
  gradebook,
  onUpdateAssignmentSubmission,
}) => {
  const { classroom } = useClassroomContext();
  const [selectedAssignment, setSelectedAssignment] = useState<string>();

  if (!classroom) return null;

  return (
    <div className="student-details">
      <div className="student-details-header">
        <img src={school} alt="" />
        <label className="label-large">{assignment.title}</label>
      </div>
      <div className="student-details-content">
        {gradebook.students.map((student) => {
          const assignmentSubmission = student.assignment_submissions.find(
            (sa) => Number(sa.assignment) === Number(assignment.getId()),
          );
          return (
            assignmentSubmission && (
              <AssignmentSubmissionRow
                key={`student-assignment-row-${student.displayName()}`}
                label={student.displayName()}
                assignmentSubmission={assignmentSubmission}
                assignment={assignment}
                selected={selectedAssignment === assignmentSubmission.getId()}
                setSelected={setSelectedAssignment}
                onUpdateAssignmentSubmission={onUpdateAssignmentSubmission}
              />
            )
          );
        })}
      </div>
    </div>
  );
};

export default AssignmentSubmissions;
