import React, { useState } from 'react';
import Assignment from 'models/Assignment';
import AssignmentCard from './AssignmentCard';

import 'view/style/catalog/components/bookRow.css';

const arrow_drop_down: string =
  require('assets/icons/arrow_drop_down.svg').default;
const arrow_right: string = require('assets/icons/arrow_right.svg').default;

interface AssignmentRowProps {
  label: string;
  assignments: Assignment[];
  onSelect: (assignment: Assignment) => void;
}

const AssignmentRow: React.FC<AssignmentRowProps> = ({
  label,
  assignments,
  onSelect,
}) => {
  const [expand, setExpand] = useState<boolean>(false);

  return (
    <div className="book-catalog-row-container">
      <div className="row" onClick={() => setExpand((prev) => !prev)}>
        <label className="label-large">{label}</label>
        <img src={expand ? arrow_drop_down : arrow_right} alt="dropdown" />
      </div>
      <div className={`book-catalog-row${expand ? ' expand' : ''}`}>
        {assignments?.map((assignment) => (
          <AssignmentCard
            key={`assignment-card-${assignment.id}`}
            assignment={assignment}
            onSelect={onSelect}
          />
        ))}
      </div>
    </div>
  );
};

export default AssignmentRow;
