import React from 'react';
import 'view/style/catalog/components/bookCard.css';
import Assignment from 'models/Assignment';

const menu_book: string = require('assets/icons/menu_book_gray.svg').default;

interface AssignmentCardProps {
  assignment: Assignment;
  onSelect?: (assignment: Assignment) => void;
}

const AssignmentCard: React.FC<AssignmentCardProps> = ({
  assignment,
  onSelect = () => {},
}) => {
  return (
    <div className="book-card" onClick={() => onSelect(assignment)}>
      <div className="book-card-cover">
        {assignment.google_id ? (
          <img src={assignment.google_id} alt="" />
        ) : (
          <img className="icon" src={menu_book} alt="" />
        )}
      </div>
      <div className="book-label">
        <label className="label-small">{assignment.title}</label>
        <label className="label-small">{assignment.min_reading_level}</label>
      </div>
    </div>
  );
};

export default AssignmentCard;
