import React, { createContext, useContext, useEffect, useState } from 'react';

interface FormContextProps<T> {
  formData: T;
  setFormData: (newData: T) => void;
}

const FormContext = createContext<FormContextProps<any>>({
  formData: {},
  setFormData: () => {},
});

export const useFormContext = <T,>() =>
  useContext<FormContextProps<T>>(FormContext);

export const FormProvider = <T,>({
  children,
  initialFormData,
}: {
  children: React.ReactNode;
  initialFormData: T;
}) => {
  const [formData, setFormData] = useState<T>(initialFormData);

  const updateFormData = (newData: T) => {
    setFormData({ ...formData, ...newData });
  };

  useEffect(() => {
    setFormData(initialFormData);
  }, [initialFormData]);

  return (
    <FormContext.Provider value={{ formData, setFormData: updateFormData }}>
      {children}
    </FormContext.Provider>
  );
};
