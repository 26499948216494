import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { useDropzone, Accept } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faTrash } from '@fortawesome/free-solid-svg-icons';
import { nanoid } from 'nanoid';

type AddImageButtonProps = {
  imageUrl?: string;
  imageFile?: File;
  onAddImage: (file?: File) => void;
  onClearImage: () => void;
};

const AddImageButton: React.FC<AddImageButtonProps> = ({
  imageUrl,
  imageFile,
  onAddImage,
  onClearImage,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(imageUrl ?? null);
  const [filePickerId] = useState<string>(`file-picker-${nanoid()}`);

  useEffect(() => {
    setPreview(imageUrl ?? null);
    if (imageFile) {
      setPreview(URL.createObjectURL(imageFile));
    }
    setFile(imageFile ?? null);

    return () => {
      setFile(null);
      setPreview(null);
    };
  }, [imageUrl, imageFile]);

  const onDrop = (acceptedFiles: File[]) => {
    const uploadedFile = acceptedFiles[0];
    setFile(uploadedFile);
    setPreview(URL.createObjectURL(uploadedFile));
    onAddImage(uploadedFile);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': [] } as Accept,
    multiple: false,
    noClick: true, // Prevent default click behavior
  });

  const handleImageClick = () => {
    const inputElement = document.getElementById(
      filePickerId,
    ) as HTMLInputElement;
    inputElement?.click();
  };

  const clearFile = (e: React.MouseEvent) => {
    e.stopPropagation();
    setFile(null);
    setPreview(null);
    onClearImage();
  };

  return (
    <div
      className="col"
      style={{
        display: 'unset',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        alignContent: 'center',
        justifyItems: 'center',
        justifySelf: 'center',
      }}
    >
      <Box
        {...getRootProps()}
        className="book-card-cover"
        onClick={handleImageClick}
        id={`${filePickerId}-box`}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: isDragActive ? 'var(--gray-4)' : 'var(--gray-2)',
          border: '2px dashed var(--gray-4)',
          borderRadius: '8px',
          cursor: 'pointer',
          transition: 'background 0.3s ease',
          position: 'relative',
        }}
      >
        <input id={filePickerId} {...getInputProps()} />
        {preview ? (
          <img
            src={preview}
            alt="Uploaded Preview"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        ) : (
          <FontAwesomeIcon icon={faImage} />
        )}
      </Box>

      <div className="row">
        <label className="label-small" htmlFor={`${filePickerId}-box`}>
          {file ? `${file.name}` : preview ? '' : 'Add an image'}
        </label>
        {(file || preview) && (
          <IconButton
            hidden={file === null}
            aria-label="clear"
            onClick={clearFile}
            sx={{
              color: 'var(--dark-pink)',
              padding: 0.5,
            }}
          >
            <FontAwesomeIcon icon={faTrash} size="xs" />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default AddImageButton;
