import React, { useEffect, useState } from 'react';
import { useFormValidation } from 'contexts/FormValidationContext';
import { useFormContext } from 'contexts/FormContext';
import { Subscription, SubscriptionFormData } from 'utils/types';
import ActionButton from 'view/components/buttons/ActionButton';
import contentService from 'services/contentService';

import 'view/style/auth/signup/subscriptionForm.css';

interface TeacherSubscriptionFormProps {
  onJoinOrganization: (formData: SubscriptionFormData) => any;
}

const TeacherSubscriptionForm: React.FC<TeacherSubscriptionFormProps> = ({
  onJoinOrganization,
}) => {
  const [numStudents, setNumStudents] = useState<number>(0);
  const { formData, setFormData } = useFormContext<SubscriptionFormData>();
  const subscriptionOptions =
    contentService.getTeacherConstants().subscriptions;
  const { setPageValidity } = useFormValidation();

  useEffect(() => {
    setPageValidity(formData.subscription !== undefined && numStudents > 0);
  }, [formData.subscription, numStudents, setPageValidity]);

  const handleSubscriptionChange = (subscription: Subscription) => {
    setFormData({
      ...formData,
      subscription: {
        ...subscription,
        price: (numStudents > 0 ? numStudents : 1) * subscription.price,
        max_users: numStudents,
      },
    });
  };

  const handleOrganizationChange = (subscription_code: string) => {
    setFormData({ ...formData, subscription_code: subscription_code });
  };

  const handleNumStudentsChange = (event: any) => {
    if (!formData.subscription) return;
    const value = Math.max(event.target.value, 0);
    setFormData({
      ...formData,
      subscription: {
        ...formData.subscription,
        max_users: value,
      },
    });
    setNumStudents(value);
  };

  return (
    <div className="login-content">
      <h3>Select Subscription</h3>
      {subscriptionOptions.map((option: Subscription) => (
        <div
          key={option.type}
          className={`subscription-select ${
            formData.subscription?.type === option.type ? 'selected' : ''
          }`}
          onClick={() => handleSubscriptionChange(option)}
        >
          <span className="label-medium">{option.type}</span>
          <span className="label-small">${option.price}/student</span>
        </div>
      ))}
      {formData.subscription && (
        <>
          <div className="row">
            <span className="label-medium">How many students?</span>
            <input
              type="number"
              value={numStudents}
              onChange={handleNumStudentsChange}
            />
          </div>
          <div className="row">
            <span className="label-medium">
              Total price: {formData.subscription?.price * numStudents}
            </span>
          </div>
        </>
      )}
      <span className="label-large" style={{ textAlign: 'center' }}>
        Or enter your subscription code:
      </span>
      <div className="subscription-select" style={{ width: '100%' }}>
        <input
          style={{ width: '80%' }}
          type="text"
          placeholder="Subscription code..."
          value={formData.subscription_code}
          onChange={(e) => handleOrganizationChange(e.target.value)}
        />
        <ActionButton
          type="go"
          onClick={() => onJoinOrganization(formData)}
          label="Join"
        />
      </div>
    </div>
  );
};

export default TeacherSubscriptionForm;
