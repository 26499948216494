import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import authService from 'services/userAuthService';
import useApiCall from 'contexts/ApiCall';
import MultiPageForm from 'view/components/common/MultiPageForm';
import { FormValidationProvider } from 'contexts/FormValidationContext';
import StudentSubscriptionForm from 'view/components/auth/signup/StudentSubscriptionForm';
import TeacherSubscriptionForm from 'view/components/auth/signup/TeacherSubscriptionForm';
import PaymentForm from 'view/components/auth/payment/PaymentForm';
import { FormProvider } from 'contexts/FormContext';
import { SubscriptionFormData } from 'utils/types';
import contentService from 'services/contentService';

const initialFormData = {
  subscription: undefined,
  subsription_code: undefined,
};

const SubscriptionPage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { accountType, userId } = useParams();
  const subscriptionContent = contentService.getSubscriptionPage();
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userId || !accountType) navigate('/');
  }, [userId, accountType, navigate]);

  const getPages = () => {
    switch (accountType) {
      case 'student':
        return [<StudentSubscriptionForm />];
      case 'teacher':
        return [
          <TeacherSubscriptionForm
            onJoinOrganization={joinSubscriptionHandler}
          />,
          <PaymentForm onSubmit={paymentSuccessfulHandler} />,
        ];
      default:
        return [];
    }
  };

  const getPageCallbacks = () => {
    switch (accountType) {
      case 'student':
        return [joinSubscriptionHandler];
      case 'teacher':
        return [undefined, paymentSuccessfulHandler];
      default:
        return [];
    }
  };

  const paymentSuccessfulHandler = (formData: any): Promise<void> => {
    return new Promise((resolve, reject) => {
      makeApiCall(authService.activateSubscription, formData, userId)
        .then((resp) => {
          navigate(`/login?token=${resp.token}`);
          resolve();
        })
        .catch((error) => {
          alert(error.message);
          reject(error);
        });
    });
  };

  const joinSubscriptionHandler = (formData: any): Promise<void> => {
    return new Promise((resolve, reject) => {
      makeApiCall(authService.joinSubscription, formData, userId)
        .then((resp) => {
          navigate(`/login/${resp.token}`);
          resolve();
        })
        .catch((error) => reject(error));
    });
  };

  return (
    <div className="login-page">
      <div className="login">
        <h3>{subscriptionContent.content.title}</h3>
        <FormProvider<SubscriptionFormData> initialFormData={initialFormData}>
          <FormValidationProvider>
            <MultiPageForm
              pages={getPages()}
              pageSubmitCallbacks={getPageCallbacks()}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              onSubmit={() => navigate('/login')}
              onExit={() => navigate('/')}
            />
          </FormValidationProvider>
        </FormProvider>
      </div>
    </div>
  );
};

export default SubscriptionPage;
