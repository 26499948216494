/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import { LandingPage } from './view/pages/landing/landing';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CatalogPage } from './view/pages/catalog/catalog';
import { NotFoundPage } from './view/pages/lost/lost';
import { StudentHomePage } from './view/pages/student/home/home';
import { StudentClassPage } from './view/pages/student/classroom/classroom';
import { StudentHelpPage } from './view/pages/student/help/help';
import { AssignmentSubmissionPage } from './view/pages/student/assignment/assignment';
import { TeacherHomePage } from './view/pages/teacher/home/home';
import { TeacherClassPage } from './view/pages/teacher/classroom/classroom';
import { TeacherHelpPage } from './view/pages/teacher/help/help';
import { TeacherAssignmentPage } from './view/pages/teacher/assignment/assignment';
import { AnalyticsPage } from './view/pages/teacher/analytics/analytics';
import { LoginPage } from './view/pages/auth/login/login';
import { SignupPage } from './view/pages/auth/signup/signup';
import { LogoutPage } from './view/pages/auth/logout/logout';
import { ClassroomAssignments } from './view/pages/teacher/classroom_assignments/classroom_assignments';
import { PrivacyPolicyPage } from './view/pages/privacy/privacy_policy';
import { Header } from './view/components/common/Header';
import { ContactPage } from './view/pages/contact/contact';
import { StudentProfilePage } from './view/pages/student/profile/profile';
import { TeacherProfilePage } from './view/pages/teacher/profile/profile';
import { TermsOfServicePage } from './view/pages/terms/terms';
import { ForgotPasswordPage } from './view/pages/auth/password_reset/forgot_password';
import { PasswordResetPage } from './view/pages/auth/password_reset/password_reset';
import SubscriptionPage from './view/pages/auth/subscription/subscription';
import StudentPracticePage from 'view/pages/student/practice/practice';
import AdminCatalog from 'view/pages/admin/catalog';
import AdminHome from 'view/pages/admin/home';
import AdminBookPage from 'view/pages/admin/book_editor';
import { NewcomersPage } from 'view/pages/student/newcomers/newcomers';

function App() {
  useEffect(() => {
    document.title = 'Orator';
  }, []);

  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* <Route path="/transcription-test" element={<TranscriptionApp />} /> */}
          <Route path="/catalog" element={<CatalogPage />} />
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/terms-of-service" element={<TermsOfServicePage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route
            path="/password-reset/:uid/:token"
            element={<PasswordResetPage />}
          />
          <Route
            path="/subscribe/:accountType/:userId"
            element={<SubscriptionPage />}
          />

          <Route path="/student/*">
            <Route path="home" element={<StudentHomePage />} />
            <Route path="classroom/:classroomId">
              <Route path="" element={<StudentClassPage />} />
              <Route
                path="assignment/:assignmentId"
                element={<AssignmentSubmissionPage />}
              />
              <Route
                path="practice/:studentId"
                element={<StudentPracticePage />}
              />
            </Route>
            <Route path="help" element={<StudentHelpPage />} />
            <Route path="profile" element={<StudentProfilePage />} />
            <Route path="language-practice" element={<NewcomersPage />} />
          </Route>

          <Route path="/teacher/*">
            <Route path="home" element={<TeacherHomePage />} />
            <Route path="classroom/:classroomId">
              <Route path="" element={<TeacherClassPage />} />
              <Route
                path="assignments/:assignmentId"
                element={<TeacherAssignmentPage />}
              />
              <Route path="new-assignment">
                <Route path="" element={<TeacherAssignmentPage />} />
                <Route
                  path=":assignmentType"
                  element={<TeacherAssignmentPage />}
                />
              </Route>
              <Route path="insights" element={<AnalyticsPage />} />
              <Route path="assignments" element={<ClassroomAssignments />} />
            </Route>
            <Route path="help" element={<TeacherHelpPage />} />
            <Route path="profile" element={<TeacherProfilePage />} />
          </Route>

          <Route path="/admin/*">
            <Route path="home" element={<AdminHome />} />
            <Route path="catalog" element={<AdminCatalog />} />
            <Route path="book" element={<AdminBookPage />} />
            <Route path="book/:bookId" element={<AdminBookPage />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
