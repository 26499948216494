import React, { useState } from 'react';
import ActionButton from 'view/components/buttons/ActionButton';
import Book from 'models/Book';
import { useParams } from 'react-router-dom';
import contentService from 'services/contentService';
import { TextEditor } from 'view/components/teacher/assignment/TextEditor';
import { FileInput } from 'view/components/teacher/assignment/FileInputs';
import { VersionTag } from 'view/components/common/VersionTag';
import Button from 'view/components/buttons/Button';
import { WordList } from 'view/components/common/WordList';

const delete_forever: string =
  require('assets/icons/delete_forever.svg').default;
const file_upload: string = require('assets/icons/file_upload.svg').default;
const menu_book: string = require('assets/icons/menu_book.svg').default;
const arrow_back: string = require('assets/icons/arrow_back.svg').default;
const add_circle_outline: string =
  require('assets/icons/add_circle_outline.svg').default;

interface AdminBookEditorProps {
  book: Book;
  coverImage?: string;
  updateBook: (event: any) => void;
  setBook: React.Dispatch<React.SetStateAction<Book | undefined>>;
  handleInputFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBookCoverChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCreateBook: (removeCover: boolean) => void;
  handleDeleteBook: () => void;
  handleUpdateText: (richText: string) => void;
  onCancel: () => void;
}

const AdminBookEditor: React.FC<AdminBookEditorProps> = ({
  book,
  coverImage,
  updateBook,
  setBook,
  handleInputFileChange,
  handleBookCoverChange,
  handleCreateBook,
  handleDeleteBook,
  handleUpdateText,
  onCancel,
}) => {
  const [removeCover, setRemoveCover] = useState<boolean>(false);
  const [tag, setTag] = useState<string>('');
  const { bookId } = useParams();
  const constants = contentService.getTeacherConstants();

  const handleAddTag = () => {
    if (tag === '' || !tag) return;
    setBook((prev: any) =>
      Book.fromServerBook({
        ...prev,
        tags: [...prev.tags, tag],
      }),
    );
    setTag('');
  };

  const handleRemoveTag = (tagToDelete: string) => {
    setBook((prev: any) =>
      Book.fromServerBook({
        ...prev,
        tags: [...prev.tags.filter((tt: string) => tt !== tagToDelete)],
      }),
    );
  };

  return (
    <div className="app-page-content">
      <div className="app-sidebar">
        <div className="col">
          <Button onClick={onCancel} leadIcon={arrow_back} label="Back" />
          <div className="assignment-sidebar-content">
            <FileInput
              label="Upload file"
              accept=".pdf, .txt, .jpeg, .jpg, .png, .heic"
              onFileChange={handleInputFileChange}
            />
            <div className="assignment-attribute-container">
              <FileInput
                id={'book-cover-upload'}
                label="Cover image"
                accept=".jpeg, .jpg, .png"
                onFileChange={handleBookCoverChange}
              />
              <div className="book-card-cover">
                <img src={coverImage ?? book.cover_image} alt="" />
              </div>
              {book.cover_image && (
                <ActionButton
                  type={removeCover ? 'go' : 'delete'}
                  label={removeCover ? 'Keep cover' : 'Remove cover'}
                  onClick={() => setRemoveCover((prev) => !prev)}
                />
              )}
            </div>
            <div className="assignment-attribute-container">
              <div className="sidebar-text">
                <label className="label-normal">Tags</label>
              </div>
              <div className="row stretch">
                <input
                  type="text"
                  placeholder="enter a tag"
                  value={tag}
                  onChange={(event) => setTag(event.target.value)}
                />
                <img
                  src={add_circle_outline}
                  alt="plus"
                  onClick={handleAddTag}
                />
              </div>
              <WordList
                words={Array.from(book.tags)}
                onDeleteWord={handleRemoveTag}
              />
            </div>
          </div>
        </div>
        <VersionTag />
      </div>
      <div className="app-main-content">
        <div className="book-details-editor">
          <div className="assignment-editor-title">
            <img src={menu_book} alt="" />
            <input
              className="title"
              type="text"
              placeholder="Book Title"
              id="title"
              value={book?.title}
              onChange={(event) => {
                updateBook(event);
              }}
            />
          </div>
          <div className="optional-book-details">
            <input
              className="normal"
              type="text"
              placeholder="Author"
              id="author"
              value={book?.author}
              onChange={updateBook}
            />
            <input
              className="normal"
              type="text"
              placeholder="Reading Difficulty"
              id="reading_difficulty"
              value={book?.reading_difficulty}
              onChange={updateBook}
            />
            <select
              className="normal"
              id="genre"
              value={book?.genre}
              onChange={updateBook}
            >
              {constants.genres.map((genre: string) => (
                <option key={`option-${genre}`} value={genre}>
                  {genre}
                </option>
              ))}
            </select>
          </div>
        </div>
        <TextEditor
          initialValue={book?.html_content ?? ''}
          onChange={handleUpdateText}
        />
        <div className="assignment-editor-actions">
          <ActionButton
            type="delete"
            onClick={handleDeleteBook}
            label="Delete"
            icon={delete_forever}
          />
          <ActionButton
            type="go"
            onClick={() => handleCreateBook(removeCover)}
            label={bookId ? 'Update' : 'Upload'}
            icon={file_upload}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminBookEditor;
