import React, { useEffect, useState } from 'react';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import QuestionResponse from 'models/QuestionResponse';
import { FrameProps } from './MultipartAssignment';
import QuestionChoices from '../assignment/QuestionChoices';

import 'view/style/student/components/newcomer/multipartAssignment.css';

const check = require('assets/icons/check-solid.svg').default;
const xMark = require('assets/icons/xmark-solid.svg').default;

const FillInBlankFrame: React.FC<FrameProps> = ({
  question,
  response,
  updateResponse,
}) => {
  const [questionText, setQuestionText] = useState<string[]>();

  useEffect(() => {
    setQuestionText(question.text.split('_').filter((t) => t !== ''));
  }, [question]);

  useEffect(() => {
    if (!question.check) return;
    if (question.choices && question.choices.length) {
      // update the response
      handleUpdateResponse(
        response.response,
        response.choice,
        response.grade > 0,
        response.feedback,
        true,
      );
      return;
    }
    // eslint-disable-next-line
  }, [question.check]);

  const handleUpdateResponse = (
    responseText: string,
    responseChoice: string,
    correct: boolean,
    feedback?: string,
    isAttempt: boolean = true,
  ) => {
    if (response.num_attempts > 0 || response.completed) return;
    const updatedResponse = QuestionResponse.fromQuestionResponse(response);
    updatedResponse.response = responseText;
    updatedResponse.choice = responseChoice;
    updatedResponse.feedback = feedback;
    updatedResponse.grade = Number(correct) * 100;
    updatedResponse.num_attempts += Number(isAttempt);
    updatedResponse.completed = updatedResponse.completed || isAttempt;

    updateResponse(updatedResponse);
  };

  const handleSelectChoice = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (response.num_attempts > 0 || response.completed) return;
    const choice = question.choices?.find(
      (c) => Number(c.id) === Number(event.target.value),
    );
    if (!choice || Number(choice.id) === Number(response.choice)) return;
    handleUpdateResponse(
      '',
      event.target.value,
      choice.correct,
      response.feedback,
      false,
    );
  };

  if (!questionText) return null;

  return (
    <div className="conversation-frame">
      <div className="question-input">
        <div className="row">
          <span className="label-medium">{questionText[0]} </span>
          <select value={response.choice} onChange={handleSelectChoice}>
            {question.choices!.map((c) => (
              <option key={`dropdown-${c.id}`} value={c.id}>
                {c.text}
              </option>
            ))}
          </select>{' '}
          <span className="label-medium">{questionText[1]}</span>
          <AudioAssistanceButton
            text={`${questionText[0]} blank ${questionText[1]}`}
          />
        </div>
        {question.choices && (
          <QuestionChoices
            choice={response.choice}
            choices={question.choices}
            highlightCorrect={question.check}
            onUpdateResponse={handleUpdateResponse}
          />
        )}
        {question.choices && response.choice && question.check ? (
          <img src={response.grade > 0 ? check : xMark} alt="" />
        ) : null}
        {response.feedback && response.feedback !== '' && (
          <div className="row">
            <img src={response.grade > 0 ? check : xMark} alt="" />
            <label className="label-small">{response.feedback}</label>
            <AudioAssistanceButton text={response.feedback} />
          </div>
        )}
      </div>
    </div>
  );
};

export default FillInBlankFrame;
