import { AxiosError } from 'axios';
import User from 'models/User';
import apiService from 'services/apiService';
import { Cookies } from 'react-cookie';
import { parseError } from 'utils/utils';
import { SignupFormData } from 'utils/types';
import CachingEngine from 'utils/CachingEngine';
import { LoginMetadata } from 'view/pages/auth/login/login';

export class UserAuthService {
  private cookies: Cookies;
  private static instance: UserAuthService;

  constructor() {
    this.cookies = new Cookies();
  }

  public static get(): UserAuthService {
    if (!UserAuthService.instance) {
      UserAuthService.instance = new UserAuthService();
    }

    return UserAuthService.instance;
  }

  authenticate(accessToken: string, refreshToken: string): void {
    this.cookies.set('accessToken', accessToken, { path: '/' });
    this.cookies.set('refreshToken', refreshToken, { path: '/' });
    apiService.authenticateApi(accessToken);
  }

  isAuthenticated(): boolean {
    const token = this.cookies.get('accessToken');
    return !!token;
  }

  getSessionUser = async (): Promise<User | null> => {
    try {
      apiService.authenticateApi(this.cookies.get('accessToken'));
      const response = await apiService.api.get('/get-session-user');

      return User.fromServerUser({
        ...response.data.user,
        token: response.data.token,
        refresh_token: response.data.refresh,
      });
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  loginUser = async (
    username: string,
    password: string,
    metadata: LoginMetadata,
  ): Promise<User> => {
    try {
      const response = await apiService.server.post('/auth/', {
        username: username.toLowerCase(),
        password: password,
        ...metadata,
      });

      this.authenticate(response.data.access, response.data.refresh);
      return User.fromServerUser({
        ...response.data.user,
        token: response.data.access,
        refresh_token: response.data.refresh,
      });
    } catch (error) {
      console.error(error);
      if (error instanceof AxiosError) {
        const errorMsg = this.parseAuthErrorResponse(error.response);

        return Promise.reject(new Error(errorMsg));
      } else {
        return Promise.reject(new Error('Something went wrong on our end'));
      }
    }
  };

  private parseAuthErrorResponse = (response?: Record<string, any>) => {
    var errorMessage = 'Something went wrong on our end!';

    if (!response) {
      return 'Something went wrong on our end!';
    }

    if (response.data.non_field_errors) {
      errorMessage = 'Wrong username or password!';
    } else {
      if (response.data) {
        if (response.data.error) {
          errorMessage = response.data.error;
        } else {
          const errorKeys = Object.keys(response.data);
          if (errorKeys.includes('username')) {
            errorMessage = `You forgot to fill in your username!`;
          } else if (errorKeys.includes('password')) {
            errorMessage = `You forgot to fill in your password!`;
          } else if (errorKeys.includes('detail')) {
            errorMessage = response.data.detail;
          }
        }
      }
    }
    return errorMessage;
  };

  getUserByEmail = async (email: string): Promise<User> => {
    try {
      const response = await apiService.api.post('/get-user-by-email/', {
        email: email.toLowerCase(),
      });
      return User.fromServerUser({
        ...response.data.user,
        token: response.data.token,
        refresh_token: response.data.refresh,
      });
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  async logoutUser(): Promise<void> {
    // blacklist the tokens on the server
    try {
      await apiService.server.post('/logout/', {
        refresh_token: this.cookies.get('refreshToken'),
      });
    } catch (error) {
      console.error(error);
    }
    this.cookies.remove('refreshToken', { path: '/' });
    this.cookies.remove('accessToken', { path: '/' });
    this.cookies.remove('cleverToken', { path: '/' });
    this.cookies.remove('googleAccessToken', { path: '/' });
    this.cookies.remove('googleRefreshToken', { path: '/' });
    await CachingEngine.clearCache();
  }

  registerUser = async (formData: SignupFormData): Promise<User> => {
    try {
      const response = await apiService.api.post('/users/', {
        ...formData,
        username: formData.username.toLowerCase(),
        email: formData.email.toLowerCase(),
      });
      return User.fromServerUser({
        ...response.data.user,
        token: response.data.token,
        refresh_token: response.data.refresh,
      });
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  activateSubscription = async (
    formData: SignupFormData,
    userId: string,
  ): Promise<Record<string, any>> => {
    try {
      const response = await apiService.api.post(
        `/users/${userId}/subscribe/`,
        formData,
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  joinSubscription = async (
    formData: SignupFormData,
    userId: string,
  ): Promise<Record<string, any>> => {
    try {
      const response = await apiService.api.post(
        `/users/${userId}/join-subscription/`,
        formData,
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  requestPasswordChangeLink = async (email: string): Promise<boolean> => {
    try {
      await apiService.api.post('/forgot-password/', { email: email });
      return true;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  resetPassword = async (
    user_id: string,
    token: string,
    password: string,
  ): Promise<boolean> => {
    try {
      await apiService.api.post('/reset-password/', {
        user_id: user_id,
        token: token,
        password: password,
      });
      return true;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  changePassword = async (
    user_id: string,
    old_password: string,
    new_password: string,
  ): Promise<any> => {
    try {
      const response = await apiService.api.post(
        `/users/${user_id}/change-password/`,
        { old_password: old_password, new_password: new_password },
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  refreshAccessToken = async (refresh_token: string): Promise<boolean> => {
    try {
      const response = await apiService.server.post('/auth/refresh/', {
        refresh: refresh_token,
      });
      return response.data.access;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };
}

const userAuthService = UserAuthService.get();
export default userAuthService;
