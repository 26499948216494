import React, { useEffect } from 'react';
import { useFormValidation } from 'contexts/FormValidationContext';
import { UserInput } from 'view/components/common/UserInput';
import { useFormContext } from 'contexts/FormContext';
import { SubscriptionFormData } from 'utils/types';

const JoinDistrictForm: React.FC = () => {
  const { formData, setFormData } = useFormContext<SubscriptionFormData>();
  const { setPageValidity } = useFormValidation();

  useEffect(() => {
    //   setPageValidity(formData.subscription_code !== undefined);
    setPageValidity(true);
  }, [formData.subscription_code, setPageValidity]);

  const handleOrganizationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, subscription_code: e.target.value });
  };

  return (
    <div className="login-content">
      <span className="label-large">
        If you are joining an existing district or subscription code, enter it
        below. Otherwise click submit to start your 30 day free trial!
      </span>
      <UserInput
        id="subscription_code"
        label="Enter your subscription code here"
        text="Enter your subscription code here"
        type="text"
        value={formData.subscription_code}
        onChange={handleOrganizationChange}
      />
      {/* <span className="label-normal">{formContent.success_message}</span> */}
    </div>
  );
};

export default JoinDistrictForm;
