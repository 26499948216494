import apiService from 'services/apiService';
import CachingEngine from 'utils/CachingEngine';
import { Language } from 'utils/types';

export class DictionaryService {
  private static instance: DictionaryService;
  public language: Language = { name: 'English', code: 'en-US' };

  public static get(): DictionaryService {
    if (!DictionaryService.instance) {
      DictionaryService.instance = new DictionaryService();
    }

    return DictionaryService.instance;
  }

  getWordDefinition = async (
    word: string,
  ): Promise<Record<string, any> | null> => {
    try {
      // check if it is cached
      const cachedDef = await CachingEngine.getData(
        `dictionary-${this.language.code}-${word}`,
      );
      if (cachedDef) return cachedDef;

      const response = await apiService.api.get(
        `/dictionary/?language=${this.language.code}&word=${word}`,
      );
      // store the definition in the cache
      await CachingEngine.setData(
        `dictionary-${this.language.code}-${word}`,
        response.data,
      );
      return response.data;
    } catch (error) {
      console.error('Error retrieving word definition');
      console.error(error);
      return null;
    }
  };

  getPhraseDefinition = async (
    word: string,
  ): Promise<Record<string, any> | null> => {
    try {
      const response = await apiService.api.get(
        `/dictionary/phrase/?language=${this.language.code}&word=${word}`,
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.error('Error retrieving word definition');
      console.error(error);
      return null;
    }
  };

  reportWordDefinition = async (
    word: string,
  ): Promise<Record<string, any> | null> => {
    try {
      const response = await apiService.api.post(`/dictionary/report/`, {
        word: word,
        language: this.language.code,
      });
      return response;
    } catch (error) {
      console.error('Error retrieving word definition');
      console.error(error);
      return null;
    }
  };

  setLanguage(language: Language): void {
    this.language = language;
  }
}

const dictionaryService = DictionaryService.get();
export default dictionaryService;
