import Question from 'models/Question';
import React from 'react';
import AddImageButton from 'view/components/buttons/AddImageButton';

import 'view/style/teacher/components/assignment/newcomer.css';

interface MultiPartConversationFrameProps {
  question: Question;
  onUpdateQuestion: (
    updatedQuestion: Question,
    submit?: boolean,
    image?: File,
  ) => void;
}

const MultiPartConversationFrame: React.FC<MultiPartConversationFrameProps> = ({
  question,
  onUpdateQuestion,
}) => {
  const handleUpdatePrompt = (text: string) => {
    const updatedQuestion = Question.fromQuestion(question);
    updatedQuestion.text = text;

    onUpdateQuestion(updatedQuestion);
  };

  const handleAddImage = (file?: File) => {
    const updatedQuestion = Question.fromQuestion(question);
    onUpdateQuestion(updatedQuestion, true, file);
  };

  const handleClearImage = () => {
    const updatedQuestion = Question.fromQuestion(question);
    updatedQuestion.image_file = undefined;
    updatedQuestion.image_url = undefined;
    onUpdateQuestion(updatedQuestion, true);
  };

  return (
    <div className="multi-part-assignment-component">
      <textarea
        id={`${question.index}-prompt`}
        placeholder="Type your prompt here"
        value={question.text}
        onChange={(e) => handleUpdatePrompt(e.target.value)}
        style={{ width: '100%', height: '100%' }}
      />
      <AddImageButton
        imageUrl={question.image_url}
        onAddImage={handleAddImage}
        onClearImage={handleClearImage}
      />
    </div>
  );
};

export default MultiPartConversationFrame;
