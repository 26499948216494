import React from 'react';
import { FileInput } from '../FileInputs';
import Assignment from 'models/Assignment';
import { TextEditor } from '../TextEditor';
import ActionButton from 'view/components/buttons/ActionButton';
import Book from 'models/Book';
import { useParams } from 'react-router-dom';
import contentService from 'services/contentService';
import AssignmentSidebar from '../AssignmentSidebar';

const add_circle_white: string =
  require('assets/icons/add_circle_white.svg').default;
const delete_forever: string =
  require('assets/icons/delete_forever.svg').default;
const file_upload: string = require('assets/icons/file_upload.svg').default;
const menu_book: string = require('assets/icons/menu_book.svg').default;
const quiz: string = require('assets/icons/quiz.svg').default;

interface BaseAssignmentFormProps {
  assignment: Assignment;
  book: Book;
  setAssignment: React.Dispatch<React.SetStateAction<Assignment | undefined>>;
  updateAssignment: (event: any) => void;
  updateBook: (event: any) => void;
  handleInputFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBookCoverChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCreateAssignment: () => void;
  handleDeleteAssignment: () => void;
  setViewBooks: () => void;
  setViewQuestions: () => void;
  handleSelectCatalogBook: (book: Book) => void;
  handleUpdateText: (richText: string) => void;
  onCancel: () => void;
}

const BaseAssignmentForm: React.FC<BaseAssignmentFormProps> = ({
  assignment,
  book,
  setAssignment,
  updateAssignment,
  updateBook,
  handleInputFileChange,
  handleBookCoverChange,
  handleCreateAssignment,
  handleDeleteAssignment,
  setViewBooks,
  setViewQuestions,
  handleUpdateText,
  onCancel,
}) => {
  const { assignmentId } = useParams();
  const constants = contentService.getTeacherConstants();

  return (
    <div className="app-page-content">
      <AssignmentSidebar
        assignment={assignment}
        book={book}
        options={[
          'key-words',
          'missed-word-interval',
          'speech-accuracy',
          'show-popup',
        ]}
        handleBookCoverChange={handleBookCoverChange}
        handleInputFileChange={handleInputFileChange}
        onCancel={onCancel}
        setAssignment={setAssignment}
        updateAssignment={updateAssignment}
        updateBook={updateBook}
      />
      <div className="app-main-content">
        <div className="book-details-editor">
          <div className="assignment-editor-title">
            <img src={menu_book} alt="" />
            <input
              className="title"
              type="text"
              placeholder="Book Title"
              id="title"
              value={book?.title}
              onChange={(event) => {
                updateBook(event);
                updateAssignment(event);
              }}
            />
          </div>
          <div className="optional-book-details">
            <input
              className="normal"
              type="text"
              placeholder="Author"
              id="author"
              value={book?.author}
              onChange={updateBook}
            />
            <input
              className="normal"
              type="number"
              placeholder="Reading Difficulty"
              id="reading_difficulty"
              value={book?.reading_difficulty.split('L')[0]}
              onChange={updateBook}
            />
            <select
              className="normal"
              id="genre"
              value={book?.genre}
              onChange={updateBook}
            >
              {constants.genres.map((genre: string) => (
                <option key={`option-${genre}`} value={genre}>
                  {genre}
                </option>
              ))}
            </select>
            <FileInput
              id={'book-cover-upload'}
              label="Cover image"
              accept=".jpeg, .jpg, .png"
              onFileChange={handleBookCoverChange}
            />
          </div>
        </div>
        <TextEditor
          initialValue={book?.html_content ?? ''}
          onChange={handleUpdateText}
        />
        <div className="assignment-editor-actions">
          {assignmentId ? (
            <ActionButton
              type="delete"
              onClick={handleDeleteAssignment}
              label="Delete"
              icon={delete_forever}
              requiresConfirmation={true}
              confirmationMessage="Are you sure you want to delete this assignment? All submissions and grades will be lost."
            />
          ) : (
            <ActionButton
              type="go"
              onClick={setViewBooks}
              label="Add From Catalog"
              icon={add_circle_white}
            />
          )}
          <ActionButton
            type="edit"
            onClick={setViewQuestions}
            label="Questions"
            icon={quiz}
          />
          <ActionButton
            type="go"
            onClick={() => handleCreateAssignment()}
            label={assignmentId ? 'Update' : 'Upload'}
            icon={file_upload}
          />
        </div>
      </div>
    </div>
  );
};

export default BaseAssignmentForm;
