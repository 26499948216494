import useApiCall from 'contexts/ApiCall';
import { useClassroomContext } from 'contexts/ClassroomContext';
import Book from 'models/Book';
import MissedWord from 'models/MissedWord';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import studentService from 'services/studentService';
import { BookReader } from 'view/components/common/BookReader';

interface ReadingPracticeProps {
  missedWords: MissedWord[];
}

const ReadingPractice: React.FC<ReadingPracticeProps> = ({ missedWords }) => {
  const [practiceBook, setPracticeBook] = useState<Book>();
  const { classroom } = useClassroomContext();
  const { studentId } = useParams();
  const makeApiCall = useApiCall();

  useEffect(() => {
    if (!studentId || !classroom || practiceBook || missedWords.length === 0)
      return;
    // generate a reading from ChatGPT, using the missed words
    const randomMissedWords = missedWords
      .sort(() => Math.random() - 0.5)
      .slice(0, 5);
    makeApiCall(
      studentService.generateReading,
      studentId,
      Array.from(new Set(randomMissedWords.map((mw) => mw.word))),
    )
      .then((resp) => {
        var book = Book.generateDefault(classroom);
        book.words = resp.text;
        book.html_content = `<p>${resp.text}</p>`;
        setPracticeBook(book);
      })
      .catch((err) => alert(err.message));
  }, [studentId, missedWords, classroom, practiceBook, makeApiCall]);

  return (
    <div className="practice-modal">
      {practiceBook ? (
        <div className="reading-container">
          <BookReader
            book={practiceBook}
            startIndex={0}
            isReading={false}
            setIsReading={() => {}}
            onMissedWord={() => {}}
          />
        </div>
      ) : (
        <h4>Generating reading...</h4>
      )}
    </div>
  );
};

export default ReadingPractice;
