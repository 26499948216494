import useApiCall from 'contexts/ApiCall';
import QuestionResponse from 'models/QuestionResponse';
import React, { useEffect, useState } from 'react';
import googleApiService from 'services/googleAPIService';
import CachingEngine from 'utils/CachingEngine';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import { FrameProps } from './MultipartAssignment';

const check = require('assets/icons/check-solid.svg').default;
const xMark = require('assets/icons/xmark-solid.svg').default;
const wormy_a: string = require('assets/images/logos/wormy-1.png');

function areSentencesEqual(sentence1: string, sentence2: string): boolean {
  // Remove special characters, convert to lowercase, and trim spaces
  const cleanSentence = (s: string) =>
    s
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .toLowerCase()
      .trim();

  return cleanSentence(sentence1) === cleanSentence(sentence2);
}

const TranscribeFrame: React.FC<FrameProps> = ({
  question,
  response,
  updateResponse,
}) => {
  // eslint-disable-next-line
  const [audioSrc, setAudioSrc] = useState();
  const makeApiCall = useApiCall();

  useEffect(() => {
    // check the student's short answer response
    if (question.check && !question.choices) {
      const correct = areSentencesEqual(question.text, response.response);
      handleUpdateResponse(response.response, correct, undefined, false);
    }

    if (question.text && !audioSrc) {
      CachingEngine.getData(`${question.index}-conversation`).then(
        (cachedAudio) => {
          if (cachedAudio) {
            setAudioSrc(cachedAudio);
          } else {
            makeApiCall(googleApiService.convertTextToSpeech, question.text)
              .then((resp) => {
                setAudioSrc(resp);
                CachingEngine.setData(`${question.index}-conversation`, resp);
              })
              .catch((error) => alert(error));
          }
        },
      );
    }

    // eslint-disable-next-line
  }, [question.check, question.text, makeApiCall]);

  const handleUpdateResponse = (
    responseText: string,
    correct: boolean,
    feedback?: string,
    isAttempt: boolean = true,
  ) => {
    const updatedResponse = QuestionResponse.fromQuestionResponse(response);
    updatedResponse.feedback = feedback;
    updatedResponse.grade = Number(correct) * 100;
    updatedResponse.num_attempts += Number(isAttempt);
    updatedResponse.completed = true;
    updatedResponse.response = responseText;

    updateResponse(updatedResponse);
  };

  return (
    <div className="conversation-frame">
      <div className="row">
        <img className="wormy" src={wormy_a} alt="wormy" />
        <AudioAssistanceButton text={question.text} audioSrc={audioSrc} />
      </div>
      <label htmlFor="question-text-area">Write what you hear</label>
      <textarea
        id="question-text-area"
        value={response.response ?? ''}
        onChange={(event) =>
          handleUpdateResponse(event.target.value, false, undefined, false)
        }
      />
      {response.completed && response.num_attempts > 0 && (
        <div className="row">
          <img src={response.grade > 0 ? check : xMark} alt="" />
          <label className="label-small">{question.text}</label>
          <AudioAssistanceButton text={response.feedback ?? ''} />
        </div>
      )}
    </div>
  );
};

export default TranscribeFrame;
