import React from 'react';
import { useUserContext } from 'contexts/UserContext';
import contentService from 'services/contentService';
import { Subscription } from 'utils/types';
import { AppPage } from 'view/components/common/AppPage';
import { calculateEndDate } from 'utils/utils';

interface SubscriptionFormProps {}

export const SubscriptionForm: React.FC<SubscriptionFormProps> = () => {
  const { user } = useUserContext();
  const subscriptionOptions =
    contentService.getTeacherConstants().subscriptions;

  if (!user?.subscription && !user?.district) return <AppPage />;

  if (user?.district) {
    return (
      <div className="app-main-content">
        <h1>Subscription</h1>
        <div className="classroom-list">
          <span className="label-normal">
            You are a member of {user.district!.name}!
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="app-main-content">
      <h1>Subscription</h1>
      <div className="classroom-list">
        <h3>My current subscription</h3>
        <br />
        <div className="form-content">
          <div
            className="assignment-details-list"
            style={{ minWidth: '300px', width: '30vw' }}
          >
            <div className="assignment-detail">
              <span className="label-normal">Name</span>
              <span className="label-normal">{user?.subscription?.name}</span>
            </div>
            <div className="assignment-detail">
              <span className="label-normal">Subscription code</span>
              <span className="label-normal">{user?.subscription?.code}</span>
            </div>
            <div className="assignment-detail">
              <span className="label-normal">Price</span>
              <span className="label-normal">${user?.subscription?.price}</span>
            </div>
            <div className="assignment-detail">
              <span className="label-normal">Start date</span>
              <span className="label-normal">
                {new Date(user.subscription!.start_date).toDateString()}
              </span>
            </div>
            <div className="assignment-detail">
              <span className="label-normal">Expires</span>
              <span className="label-normal">
                {calculateEndDate(
                  new Date(user.subscription!.start_date),
                  user.subscription!.duration,
                ).toDateString()}
              </span>
            </div>
          </div>
        </div>
        {!user?.district && (
          <>
            <br />
            <h3>Available options</h3>
            <br />
            {subscriptionOptions.map((option: Subscription) => (
              <div
                key={`subscription-${option.type}`}
                className="subscription-select"
              >
                <span className="label-normal">{option.type}</span>
                <span className="label-small">${option.price}/student</span>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
