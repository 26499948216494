import useApiCall from 'contexts/ApiCall';
import { useUserContext } from 'contexts/UserContext';
import Book from 'models/Book';
import Question from 'models/Question';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { adminService } from 'services/adminService';
import { AdminBookPreview } from 'view/components/admin/catalog/BookPreview';
import ActionButton from 'view/components/buttons/ActionButton';
import { BookRow } from 'view/components/catalog/BookRow';
import { AppPage } from 'view/components/common/AppPage';
import { BookReader } from 'view/components/common/BookReader';
import Modal from 'view/components/common/Modal';
import { SearchBar } from 'view/components/common/SearchBar';
import { FileInput } from 'view/components/teacher/assignment/FileInputs';
import { QuestionEditor } from 'view/components/teacher/assignment/QuestionEditor';

const AdminCatalog: React.FC = () => {
  const { user } = useUserContext();
  const [publicBooks, setPublicBooks] = useState<Book[]>();
  const [reviewBooks, setReviewBooks] = useState<Book[]>();
  const [displayBooks, setDisplayBooks] = useState<Book[]>();
  const [selectedBook, setSelectedBook] = useState<Book | null>(null);
  const [viewText, setViewText] = useState<boolean>(false);
  const [viewQuestions, setViewQuestions] = useState<boolean>(false);
  const navigate = useNavigate();
  const makeApiCall = useApiCall();

  useEffect(() => {
    if (user?.account_type === 'Admin') return;
    navigate('/admin/home');
  }, [user, navigate]);

  useEffect(() => {
    if (publicBooks || reviewBooks) return;
    // get the public books, and books under review from the server
    makeApiCall(adminService.getCatalogBooks)
      .then((resp) => {
        setPublicBooks(resp.public_books);
        setDisplayBooks(resp.public_books);
        setReviewBooks(resp.review_books);
      })
      .catch((err) => alert(err));
  }, [publicBooks, reviewBooks, makeApiCall]);

  const handleSelectBook = async (book: Book) => {
    if (book && (!book.html_content || book.html_content === '')) {
      await book.getHtmlContent();
    }
    setSelectedBook(book);
  };

  const handleBookStatusChange = (book: Book, accept: boolean) => {
    if (book.is_public) {
      if (!accept) {
        setPublicBooks((prev) => [
          ...(prev as Book[]).filter((b) => b.id !== book.id),
        ]);
      }
    } else {
      if (accept) {
        setPublicBooks((prev) => [...(prev as Book[]), book]);
      }
      setReviewBooks((prev) => [
        ...(prev as Book[]).filter((b) => b.id !== book.id),
      ]);
    }

    makeApiCall(adminService.reviewCatalogBook, book, accept)
      .then((resp) => alert('Success'))
      .catch((err) => alert(err));
  };

  const handleMultiBookUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file && file.type === 'application/json') {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const text = e.target?.result;
        try {
          const json = JSON.parse(text as string);
          json.books.map((book: Book) =>
            makeApiCall(adminService.createBook, book, null, false, false),
          );
        } catch (error) {
          console.error('Error parsing JSON!', error);
        }
      };

      reader.onerror = () => {
        console.error('Error reading the file');
      };

      reader.readAsText(file);
    } else {
      console.error('Please upload a valid JSON file.');
    }
  };

  const handleSearch = (searchTerm: string) => {
    // Do all filtering of displayed books here
    if (searchTerm === undefined) return;
    const lowerSearchTerm = searchTerm.toLowerCase();
    setDisplayBooks(
      publicBooks?.filter((book) => {
        const titleContainsSearchTerm = book.title
          .toLowerCase()
          .includes(lowerSearchTerm);
        const tagsContainsSearchTerm = book.tags.some((tag) =>
          tag.toLowerCase().includes(lowerSearchTerm),
        );
        return titleContainsSearchTerm || tagsContainsSearchTerm;
      }),
    );
  };

  const handleCreateQuestion = (text: string, choices?: any[]) => {
    if (text === '' || !selectedBook) return;
    const newQuestion = {
      text: text,
      book: selectedBook.id,
      choices: choices,
    };
    // onCreateQuestion(newQuestionText);
    makeApiCall(adminService.createDefaultQuestion, newQuestion)
      .then((resp) => {
        alert('Question created');
        const updatedBook = Book.fromBook(selectedBook);
        updatedBook.default_questions = [
          ...(selectedBook.default_questions ?? []),
          Question.fromServerQuestion({
            ...resp,
            index: (selectedBook.default_questions?.length ?? 0) + 1,
          }),
        ];
        setSelectedBook(updatedBook);
      })
      .catch((err) => alert(err));
  };

  const handleUpdateQuestion = (question: Question, submit: boolean = true) => {
    if (!selectedBook?.default_questions) return;
    const updatedBook = Book.fromBook(selectedBook);
    const questionIndex = selectedBook.default_questions.findIndex(
      (q) => Number(q.id) === Number(question.id),
    );
    if (questionIndex === -1) {
      alert('code error, text Zach');
      return;
    }
    if (submit) {
      makeApiCall(adminService.updateDefaultQuestion, question, selectedBook.id)
        .then((resp) => {
          alert('Question updated');
          updatedBook.default_questions = [
            ...selectedBook.default_questions!.slice(0, questionIndex),
            Question.fromServerQuestion({ ...resp, index: questionIndex + 1 }),
            ...selectedBook.default_questions!.slice(questionIndex + 1),
          ];
          setSelectedBook(updatedBook);
        })
        .catch((err) => alert(err));
    } else {
      updatedBook.default_questions = [
        ...selectedBook.default_questions!.slice(0, questionIndex),
        question,
        ...selectedBook.default_questions!.slice(questionIndex + 1),
      ];
      setSelectedBook(updatedBook);
    }
  };

  const handleDeleteQuestion = (question: Question) => {
    if (!selectedBook) return;
    makeApiCall(adminService.deleteDefaultQuestion, question)
      .then((resp) => {
        alert('Question deleted');
        const updatedBook = Book.fromBook(selectedBook);
        updatedBook.default_questions = selectedBook.default_questions!.filter(
          (q) => Number(q.id) !== Number(question.id),
        );
        setSelectedBook(updatedBook);
      })
      .catch((err) => alert(err));
  };

  return (
    <AppPage>
      <div className="app-main-content">
        <div className="row">
          <h2>Admin Catalog</h2>
          <ActionButton
            type="go"
            label="Add Book"
            onClick={() => navigate('/admin/book')}
          />
          <FileInput
            id="multi-book-upload"
            label="Multi-book Upload"
            accept=".json"
            onFileChange={handleMultiBookUpload}
          />
        </div>
        <SearchBar onSearch={handleSearch} />
        <div className="book-catalog-content">
          {displayBooks && (
            <BookRow
              label="Public"
              books={displayBooks}
              onSelect={(book) => handleSelectBook(book)}
            />
          )}
          {reviewBooks && (
            <BookRow
              label="Under Review"
              books={reviewBooks}
              onSelect={(book) => handleSelectBook(book)}
            />
          )}
        </div>
      </div>
      <Modal
        isOpen={selectedBook !== null}
        onClose={() => setSelectedBook(null)}
      >
        <AdminBookPreview
          book={selectedBook as Book}
          onView={() => setViewText(true)}
          onQuestions={() => setViewQuestions(true)}
          onStatusChange={handleBookStatusChange}
          onEdit={(book) => navigate(`/admin/book/${book.id}`)}
        />
      </Modal>
      <Modal
        isOpen={selectedBook !== null && viewText}
        onClose={() => setViewText(false)}
      >
        <div className="reading-container">
          <BookReader
            book={selectedBook as Book}
            startIndex={0}
            isReading={false}
            showMissedWordPopup={false}
            setIsReading={() => {}}
            onMissedWord={() => {}}
          />
        </div>
      </Modal>
      {selectedBook && (
        <Modal
          isOpen={selectedBook !== null && viewQuestions}
          onClose={() => setViewQuestions(false)}
        >
          <QuestionEditor
            book={selectedBook as Book}
            questions={selectedBook?.default_questions ?? []}
            isAutoGenerateAllowed={true}
            setIsAutoGenerateAllowed={() => {}}
            onCreateQuestion={handleCreateQuestion}
            onUpdateQuestion={handleUpdateQuestion}
            onDeleteQuestion={handleDeleteQuestion}
            onExit={() => setViewQuestions(false)}
          />
        </Modal>
      )}
    </AppPage>
  );
};

export default AdminCatalog;
