import React, { useEffect, useState } from 'react';
import { useClassroomContext } from 'contexts/ClassroomContext';
import { useNavigate } from 'react-router-dom';
import teacherService from 'services/teacherService';
import { SearchBar } from 'view/components/common/SearchBar';
import Assignment from 'models/Assignment';
import TeacherGradebook from 'models/TeacherGradebook';
import { Filter, FilterOption } from 'view/components/common/Filter';
import { AppPage } from 'view/components/common/AppPage';
import SidebarButton from 'view/components/buttons/SidebarButton';
import { StatusIcon } from 'view/components/common/StatusIcon';
import AssignmentSubmissions from 'view/components/teacher/classroom/AssignmentSubmissions';
import Modal from 'view/components/common/Modal';
import AssignmentSubmission from 'models/AssignmentSubmission';
import useApiCall from 'contexts/ApiCall';
import AppSidebar from 'view/components/common/Sidebar';
import contentService from 'services/contentService';
import ItemPanel from 'view/components/common/ItemPanel';
import AssignmentTypeForm from 'view/components/teacher/assignment/AssignmentTypeForm';

import 'view/style/teacher/classroom.css';

const arrow_back_white: string =
  require('assets/icons/arrow_back_white.svg').default;
const add_circle_outline: string =
  require('assets/icons/add_circle_white.svg').default;

export const ClassroomAssignments: React.FC = () => {
  const { classroom } = useClassroomContext();
  const [displayAssignments, setDisplayAssignments] = useState<Assignment[]>(
    [],
  );
  const [selectedAssignment, setSelectedAssignment] = useState<Assignment>();
  const [gradebook, setGradebook] = useState<TeacherGradebook>();
  const [averagesDisplay, setAveragesDisplay] = useState<FilterOption[]>(
    contentService.getTeacherConstants().display_averages,
  );
  const [averageDisplay, setAverageDisplay] = useState<FilterOption>(
    contentService.getTeacherConstants().display_averages[0],
  );
  const [viewAssignmentSubmissions, setViewAssignmentSubmissions] =
    useState<boolean>(false);
  const [viewAddAssignment, setViewAddAssignment] = useState<boolean>(false);
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (gradebook || !classroom) return;
    const cachedGradebook = teacherService.getCachedGradebook();
    if (cachedGradebook) setGradebook(cachedGradebook);
    else {
      makeApiCall(teacherService.getGradebook, classroom.getId())
        .then((respGradebook) => setGradebook(respGradebook))
        .catch((error) => alert(error.message));
    }
  }, [gradebook, classroom, makeApiCall]);

  useEffect(() => {
    if (!gradebook?.assignments) return;
    setDisplayAssignments(gradebook.assignments);
  }, [gradebook?.assignments]);

  const handleUpdateAssignmentSubmission = (
    assignmentSubmission: AssignmentSubmission,
    field: string,
    value: any,
  ) => {
    const updatedAssignmentSubmission =
      AssignmentSubmission.fromServerAssignmentSubmission({
        ...assignmentSubmission,
        [field]: value,
      });
    makeApiCall(
      teacherService.updateAssignmentSubmission,
      updatedAssignmentSubmission,
    )
      .then((respAssignment) => {
        setGradebook((prevGradebook) =>
          prevGradebook?.updateAssignmentSubmission(respAssignment),
        );
      })
      .catch((error) => alert(error.message));
  };

  const handleBack = () => {
    if (!classroom) return;
    if (selectedAssignment) {
      setSelectedAssignment(undefined);
      return;
    }
    navigate(`/teacher/classroom/${classroom.getId()}`);
  };

  const handleCreateAssignment = () => {
    setViewAddAssignment(true);
  };

  const handleEditAssignment = () => {
    if (!classroom || !selectedAssignment) return;
    navigate(`${selectedAssignment.getId()}/`);
  };

  const handleAverageDisplayChange = (
    checked: boolean,
    displayOption: FilterOption,
  ) => {
    setAveragesDisplay((prev) => {
      const updatedDisplayOptions = prev.map((option) =>
        option === displayOption
          ? { ...option, is_active: checked }
          : { ...option, is_active: false },
      );
      return updatedDisplayOptions;
    });
    if (checked) setAverageDisplay(displayOption);
  };

  const filterAssignments = (searchTerm: string) => {
    if (!gradebook?.assignments) return;
    setDisplayAssignments(
      gradebook.assignments.filter((a) =>
        a.title.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
  };

  const handleViewAssignmentSubmissions = (
    event: React.MouseEvent<HTMLDivElement>,
    assignment: Assignment,
  ) => {
    event.stopPropagation();
    event.preventDefault();

    setViewAssignmentSubmissions(true);
    setSelectedAssignment(assignment);
  };

  if (!gradebook) return <AppPage />;

  return (
    <AppPage>
      <div className="app-page-content">
        <AppSidebar onExit={handleBack}>
          <>
            <SearchBar onSearch={filterAssignments} />
            <Filter
              type="onechoice"
              label="Averages"
              options={averagesDisplay}
              onOptionChange={handleAverageDisplayChange}
            />
            {selectedAssignment && gradebook && (
              <>
                <div className="assignment-detail-header">
                  <label className="label-medium">
                    {selectedAssignment.title}
                  </label>
                </div>
                <div className="assignment-details-list">
                  <div className="assignment-detail">
                    <label className="label-small">Reading Group</label>
                    <label className="label-small">
                      {`${selectedAssignment.min_reading_level} - ${selectedAssignment.max_reading_level}`}
                    </label>
                  </div>
                  <div className="assignment-detail">
                    <label className="label-small">Completion</label>
                    <label className="label-small">
                      {
                        gradebook.averages.assignments[
                          selectedAssignment.getId()
                        ].completion_score
                      }
                      %
                    </label>
                  </div>
                  <div className="assignment-detail">
                    <label className="label-small">Grade</label>
                    <label className="label-small">
                      {
                        gradebook.averages.assignments[
                          selectedAssignment.getId()
                        ].grade
                      }
                      %
                    </label>
                  </div>
                  <div className="assignment-detail">
                    <label className="label-small">Accuracy</label>
                    <label className="label-small">
                      {
                        gradebook.averages.assignments[
                          selectedAssignment.getId()
                        ].correctness_score
                      }
                      %
                    </label>
                  </div>
                  <div className="assignment-detail">
                    <label className="label-small">Key Word Accuracy</label>
                    <label className="label-small">
                      {
                        gradebook.averages.assignments[
                          selectedAssignment.getId()
                        ].key_word_accuracy_score
                      }
                      %
                    </label>
                  </div>
                  <div className="assignment-detail">
                    <label className="label-small">Completion Time</label>
                    <label className="label-small">
                      {
                        gradebook.averages.assignments[
                          selectedAssignment.getId()
                        ].completion_time
                      }
                      %
                    </label>
                  </div>
                </div>
              </>
            )}
            <SidebarButton
              type="go"
              onClick={handleCreateAssignment}
              icon={add_circle_outline}
              label="New Assignment"
            />
          </>
        </AppSidebar>
        <div className="app-main-content">
          <h1>{classroom?.name} Assignments</h1>
          <div className="item-list">
            {displayAssignments &&
              displayAssignments.map((assignment) => (
                <ItemPanel
                  key={`assignment-${assignment.getId()}`}
                  id={`assignment-${assignment.getId()}`}
                  selected={selectedAssignment === assignment}
                  onClick={() => setSelectedAssignment(assignment)}
                  onDoubleClick={handleEditAssignment}
                >
                  <div className="student-row-info">
                    <span className="label-normal">{assignment.title}</span>
                    <StatusIcon
                      type="todo"
                      text="View Submissions"
                      onClick={(event) =>
                        handleViewAssignmentSubmissions(event, assignment)
                      }
                    />
                  </div>
                  {selectedAssignment === assignment ? (
                    <>
                      <span className="label-medium">Edit</span>
                      <img
                        className="arrow-forward"
                        src={arrow_back_white}
                        alt=""
                      />
                    </>
                  ) : (
                    averageDisplay &&
                    averageDisplay.alias &&
                    gradebook?.averages?.assignments[assignment.getId()] && (
                      <span className="label-small">
                        {averageDisplay?.value} Average:{' '}
                        {
                          gradebook?.averages?.assignments[assignment.getId()][
                            averageDisplay?.alias as
                              | 'grade'
                              | 'completion_score'
                              | 'correctness_score'
                              | 'key_word_accuracy_score'
                              | 'completion_time'
                          ]
                        }
                        %
                      </span>
                    )
                  )}
                </ItemPanel>
              ))}
          </div>
        </div>
      </div>
      <Modal
        isOpen={viewAddAssignment}
        onClose={() => setViewAddAssignment(false)}
      >
        <AssignmentTypeForm />
      </Modal>
      {selectedAssignment && (
        <Modal
          isOpen={viewAssignmentSubmissions}
          onClose={() => setViewAssignmentSubmissions(false)}
        >
          <AssignmentSubmissions
            assignment={selectedAssignment}
            gradebook={gradebook}
            onUpdateAssignmentSubmission={handleUpdateAssignmentSubmission}
          />
        </Modal>
      )}
    </AppPage>
  );
};
