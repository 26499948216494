import React, { useEffect } from 'react';
import { FrameProps } from './MultipartAssignment';
import useApiCall from 'contexts/ApiCall';
import aiService from 'services/aiService';
import QuestionResponse from 'models/QuestionResponse';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import QuestionChoices from '../assignment/QuestionChoices';

const check = require('assets/icons/check-solid.svg').default;
const xMark = require('assets/icons/xmark-solid.svg').default;

const QuestionFrame: React.FC<FrameProps> = ({
  question,
  response,
  updateResponse,
}) => {
  const makeApiCall = useApiCall();

  useEffect(() => {
    if (!question.check) return;
    if (question.choices && question.choices.length) {
      // update the response
      handleUpdateResponse(
        response.response,
        response.choice,
        response.grade > 0,
        response.feedback,
        true,
      );
      return;
    }
    // check the student's short answer response
    makeApiCall(aiService.gradeShortAnswer, question.text, response.response)
      .then((resp) =>
        handleUpdateResponse(
          response.response,
          response.choice,
          resp.correct,
          resp.feedback,
        ),
      )
      .catch((error) => alert(error));
    // eslint-disable-next-line
  }, [question.check]);

  const handleUpdateResponse = (
    responseText: string,
    responseChoice: string,
    correct: boolean,
    feedback?: string,
    isAttempt: boolean = true,
  ) => {
    const updatedResponse = QuestionResponse.fromQuestionResponse(response);
    updatedResponse.response = responseText;
    updatedResponse.choice = responseChoice;
    updatedResponse.feedback = feedback;
    updatedResponse.grade = Number(correct) * 100;
    updatedResponse.num_attempts += Number(isAttempt);
    updatedResponse.completed = updatedResponse.completed || isAttempt;

    updateResponse(updatedResponse);
  };

  return (
    <div className="conversation-frame">
      <div className="question-input">
        <div className="row">
          <label className="label-medium">{question.text}</label>
          <AudioAssistanceButton text={question.text} />
        </div>
        {question.choices?.length ? (
          <QuestionChoices
            choice={response.choice}
            choices={question.choices}
            highlightCorrect={question.check}
            onUpdateResponse={() =>
              response.num_attempts === 0 &&
              !response.completed &&
              handleUpdateResponse
            }
          />
        ) : (
          <textarea
            value={response.response ?? ''}
            onChange={(event) =>
              handleUpdateResponse(
                event.target.value,
                response.choice,
                false,
                response.feedback,
                false,
              )
            }
          />
        )}
        {question.choices && response.choice && question.check ? (
          <img src={response.grade > 0 ? check : xMark} alt="" />
        ) : null}
        {response.feedback && response.feedback !== '' && (
          <div className="row">
            <img src={response.grade > 0 ? check : xMark} alt="" />
            <label className="label-small">{response.feedback}</label>
            <AudioAssistanceButton text={response.feedback} />
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionFrame;
