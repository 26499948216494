import React from 'react';
import ActionButton from 'view/components/buttons/ActionButton';
import Modal from './Modal';

interface ConfirmationModalProps {
  isOpen: boolean;
  confirmationType: 'submit' | 'delete';
  confirmationMessage: string;
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  confirmationType,
  confirmationMessage,
  onConfirm,
  onCancel,
}) => {
  const handleConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    onCancel();
    onConfirm(event);
  };

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <div className="form">
        {/* <label className="label-large">{formContent.title}</label> */}
        <div className="form-contents">
          <span className="label-medium" style={{ color: '#B1243F' }}>
            {confirmationMessage}
          </span>
          <div className="row">
            <ActionButton
              type="delete"
              onClick={handleConfirm}
              label="Confirm"
            />
            <ActionButton type="go" onClick={onCancel} label="Cancel" />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
