import React, { ReactNode, useState } from 'react';

import 'view/style/common/components/multiTabForm.css';

interface MultiTabFormProps {
  labels: string[];
  formContent: ReactNode[];
}

const MultiTabForm: React.FC<MultiTabFormProps> = ({ labels, formContent }) => {
  const [selectedIdx, setSelectedIdx] = useState<number>(0);

  return (
    <div className="form">
      <div className="tab-headers">
        {labels.map((label, idx) => (
          <span
            key={`tab-header-label-${idx}-${label}`}
            className={`tab-header-label ${
              idx === selectedIdx ? 'active-label' : ''
            }`}
            onClick={() => setSelectedIdx(idx)}
          >
            {label}
          </span>
        ))}
      </div>
      {formContent[selectedIdx]}
    </div>
  );
};

export default MultiTabForm;
