import React, { useEffect } from 'react';
import { useFormValidation } from 'contexts/FormValidationContext';
import { UserInput } from 'view/components/common/UserInput';
import { useFormContext } from 'contexts/FormContext';
import { SubscriptionFormData } from 'utils/types';

import 'view/style/auth/signup/subscriptionForm.css';

const StudentSubscriptionForm: React.FC = () => {
  const { formData, setFormData } = useFormContext<SubscriptionFormData>();
  const { setPageValidity } = useFormValidation();

  useEffect(() => {
    setPageValidity(formData.subscription_code !== undefined);
  }, [formData.subscription_code, setPageValidity]);

  const handleOrganizationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, subscription_code: e.target.value });
  };

  return (
    <div className="login-content">
      <h3>Join Now</h3>
      <UserInput
        id="subscription_code"
        label="Enter your Organization code here"
        text="Enter your Organization code here"
        type="text"
        value={formData.subscription_code}
        onChange={handleOrganizationChange}
      />
    </div>
  );
};

export default StudentSubscriptionForm;
