import React, { createContext, useContext, useState, ReactNode } from 'react';
import ConfirmationModal from 'view/components/common/ConfirmationModal';

interface ConfirmationContextType {
  openModal: (
    message: string,
    onConfirm: React.MouseEventHandler<HTMLButtonElement>,
  ) => void;
  closeModal: () => void;
}

const ConfirmationContext = createContext<ConfirmationContextType | null>(null);

export const useConfirmation = () => useContext(ConfirmationContext)!;

export const ConfirmationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState<
    React.MouseEventHandler<HTMLButtonElement>
  >(() => {});

  const openModal = (
    message: string,
    onConfirm: React.MouseEventHandler<HTMLButtonElement>,
  ) => {
    setMessage(message);
    setOnConfirm(() => onConfirm);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setMessage('');
    setOnConfirm(() => {});
  };

  return (
    <ConfirmationContext.Provider value={{ openModal, closeModal }}>
      {children}
      <ConfirmationModal
        isOpen={isModalOpen}
        confirmationMessage={message}
        confirmationType="delete"
        onConfirm={onConfirm}
        onCancel={closeModal}
      />
    </ConfirmationContext.Provider>
  );
};
